import CONFIGURATION from '../configuration';
import ObjectsType from '../constants/ObjectsType';

export function url(target) {
  return `${CONFIGURATION.BASE_URL}${target}`;
}

/* istanbul ignore next */
export function redirectTo(target) {
  /* istanbul ignore next */
  window.location.href = url(target);
}

export function reload() {
  window.location.reload();
}

function toDocumentTop() {
  document.body.scrollTop = 0; // For Chrome, Safari and Opera
  document.documentElement.scrollTop = 0; // For IE and FF
}

export function navigateTo(target, reloadTarget = false) {
  if (window?.app?.browserHistory) {
    window.app.browserHistory.push(url(target));
    toDocumentTop();
    if (reloadTarget) {
      reload();
    }
  }
}

/* istanbul ignore next */
export function scrollToTop() {
  window.scrollTo(0, 0);
}

export function goBack() {
  window.app.browserHistory.goBack();
}

export function isTouchDevice() {
  return (
    'ontouchstart' in window || navigator.maxTouchPoints > 0 // works on most browsers
  ); // works on IE10/11 and Surface
}

export function isMobileDevice() {
  if (
    navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } if (navigator.userAgent.match(/Intel Mac/i) && navigator.maxTouchPoints > 1 && !window.MSStream) {
    return true;
  }
  return false;
}
/* istanbul ignore next */
export function redirectToExtern(target) {
  /* istanbul ignore next */
  window.window.location.href = target;
}

export function redirectToExternNewWindow(ulr) {
  /* istanbul ignore next */
  window.open(ulr, '_blank');
}
/* istanbul ignore next */
export function stripUrlOptions(urlPath) {
  return urlPath.toString().split('?')[0];
}
/* istanbul ignore next */
export function currentUrl() {
  return window.location.href;
}

export function locale() {
  /* istanbul ignore next */
  return (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
}

export function isMobileView() {
  return window.innerWidth <= 768;
}

export function getObjectType(pathname) {
  const regex = new RegExp(Object.values(ObjectsType).join('|'), 'i');
  const match = pathname.match(regex);
  return match ? match[0] : '';
}

/* needed for integration test */
window.navigateTo = navigateTo;
