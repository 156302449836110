import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { ChipsSkeleton } from '../Chips';

export default function ListItemSkeleton({ withChips = true }) {
  const imageSize = withChips ? 60 : 40;

  return (
    <div className="list-item-skeleton" data-testid="list-item-skeleton">
      <Skeleton height={imageSize} width={imageSize} />
      <div className="list-item-skeleton-content">
        <Skeleton width="70%" />
        <Skeleton width="40%" />
        {withChips && (
        <ChipsSkeleton />
        )}
      </div>
      <Skeleton height={20} width={30} />
    </div>
  );
}

ListItemSkeleton.propTypes = {
  withChips: PropTypes.bool,
};
