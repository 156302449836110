import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { refreshSession, deleteSession } from '../actions/sessionActions';

const SessionContext = React.createContext();

const sessionShape = PropTypes.shape({
  authenticated: PropTypes.bool,
  subscriptionStorageQuota: PropTypes.number,
  activeSubscription: PropTypes.bool,
  refreshSession: PropTypes.func,
  subscriptionType: PropTypes.string,
  token: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  applicationSpecifics: PropTypes.object,
  reseller: PropTypes.shape({
    customer: PropTypes.shape({
      email: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
  }),
});

class SessionContextProvider extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    deprecatedSession: sessionShape,
    children: PropTypes.node.isRequired,
    testSession: PropTypes.shape({}),
  };

  static defaultProps = {
    testSession: {},
  };

  constructor() {
    super();
    /* istanbul ignore next */
    this.refreshSession = this.refreshSession.bind(this);
    this.deleteSession = this.deleteSession.bind(this);
    this.session = this.session.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { authenticated, activeSubscription } = this.props.deprecatedSession;

    if (this.refreshSessionResolve && (prevProps.deprecatedSession.authenticated !== authenticated || prevProps.deprecatedSession.activeSubscription !== activeSubscription)) {
      this.refreshSessionResolve(this.session());
    }
  }

  session() {
    const { deprecatedSession, testSession } = this.props;
    return {
      authenticated: deprecatedSession.authenticated,
      activeSubscription: deprecatedSession.activeSubscription,
      subscriptionStorageQuota: deprecatedSession.subscriptionStorageQuota,
      subscriptionType: deprecatedSession.subscriptionType,
      token: deprecatedSession.token,
      applicationSpecifics: deprecatedSession.applicationSpecifics,
      reseller: deprecatedSession.reseller,
      refreshSession: this.refreshSession,
      deleteSession: this.deleteSession,
      ...testSession,
    };
  }

  refreshSession() {
    const { dispatch } = this.props;
    return new Promise((resolve) => {
      dispatch(refreshSession());
      this.refreshSessionResolve = resolve;
    });
  }

  deleteSession() {
    const { dispatch } = this.props;
    dispatch(deleteSession());
  }

  render() {
    const { children } = this.props;
    return (
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      <SessionContext.Provider value={{ session: this.session() }}>
        {children}
      </SessionContext.Provider>
    );
  }
}

const ConnectedSessionContextProvider = connect((state) => ({ deprecatedSession: state.session }))(SessionContextProvider);

const withSession = (ChildComponent) => {
  function ConnectedComponent(props) {
    return (
      <SessionContext.Consumer>
        {(context) => <ChildComponent {...props} session={context.session} />}
      </SessionContext.Consumer>
    );
  }
  ConnectedComponent.displayName = ChildComponent.displayName || ChildComponent.name;
  return ConnectedComponent;
};

export {
  // sessionShape #FIXME: first we need to kill the old sessionShape
  ConnectedSessionContextProvider as SessionContextProvider,
  withSession,
};
