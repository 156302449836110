import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TreeItem from './TreeItem';

function Tree({
  list, selectedItems, setSelectedItems, disabled = false, showIsEndress = false,
}) {
  const [tree, setTree] = useState([]);

  function cleanupNonExistingParents(items) {
    const itemIds = new Set(items.map((item) => item.id));
    return items.map((item) => {
      const correctedParent = { ...item };
      if (correctedParent.parent) {
        const parentId = correctedParent.parent.id;
        if (!itemIds.has(parentId)) {
          delete correctedParent.parent;
        }
      }
      return correctedParent;
    });
  }

  function calculateTree(items, parentId = null) {
    return items
      .filter((item) => (item.parent ? item.parent.id : null) === parentId)
      .map((item) => ({
        ...item,
        children: calculateTree(items, item.id),
      }));
  }

  useEffect(() => {
    const updatedList = cleanupNonExistingParents(list);
    const updatedTree = calculateTree(updatedList);
    setTree(updatedTree);
  }, [list]);

  const addSelectedItem = (item) => setSelectedItems((prevState) => ([...prevState, item]));

  const removeSelectedItem = (item) => setSelectedItems((prevState) => (
    prevState.filter((selectedItem) => selectedItem.code !== item.code)
  ));

  const selectChildren = (item, isSelected) => {
    item.children.forEach((child) => {
      const isChildSelected = !!selectedItems.find((i) => i.code === child.code);
      if (isSelected && isChildSelected) { removeSelectedItem(child); } else if (!isSelected && !isChildSelected) { addSelectedItem(child); }

      if (child.children.length > 0) selectChildren(child, isSelected);
    });
  };

  const handleOnChange = (treeItem) => {
    const isSelected = !!selectedItems.find((i) => i.code === treeItem.code);
    selectChildren(treeItem, isSelected);
    if (isSelected) { removeSelectedItem(treeItem); } else addSelectedItem(treeItem);
  };

  return (
    <ul className={`tree ${disabled ? 'disabled' : ''}`}>
      {tree.map((item) => (
        <TreeItem
          key={`root-tree-item-${item.id}`}
          treeItem={item}
          onChange={handleOnChange}
          selectedItems={selectedItems}
          disabled={disabled}
          showIsEndress={showIsEndress}
        />
      ))}
      {tree.length === 0 && (
        <li className="no-search-results-text">
          <FormattedMessage id="search.no_results_found" />
        </li>
      )}
    </ul>
  );
}

Tree.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showIsEndress: PropTypes.bool,
};

export default Tree;
