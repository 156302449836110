/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { AlertType } from '../../constants';
import PictureGallery from './PictureGallery';
import SubscriptionLimitClue from '../Subscriptions/SubscriptionLimitClue';
import FilesUpload from '../Files/FilesUpload';
import { InputAlert } from '../Form';
import { DeleteActionButton } from '../ActionBar';
import { scaleImageFile } from '../../utils/imageUtils';
import { extractPictures } from '../../extractors';
import {
  sessionShape,
} from '../../shapes';
import {
  fileStorageAlertExists,
} from '../../utils';
import { useApi } from '../../hooks';
import {
  subscriptionShape,
  withSession,
  withSubscription,
} from '../../context';
import FileStorageLimitHelper from '../Files/FileStorageLimitHelper';
import Loader from '../Loader';

const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];
const MAX_PICTURE_SIZE = 1024 * 1024;

export function EditPictures({
  model,
  modelId,
  session,
  subscription,
  placeholder,
}) {
  const [rejectFiles, setRejectFiles] = useState();
  const [selectedPicture, setSelectedPicture] = useState();
  const [currentPictureIndex, setCurrentPictureIndex] = useState(-1);
  const [currentFileStorage, setCurrentFileStorage] = useState(subscription?.usage?.file_storage || 0);
  const fileStorageLimit = (model !== 'products')
    ? session?.subscriptionStorageQuota
    : Number.POSITIVE_INFINITY;

  const isFileStorageExceeded = fileStorageAlertExists(subscription);
  const intl = useIntl();
  const api = useApi();
  const queryClient = useQueryClient();

  const calculatePictureIndex = (picturesData, pictureId) => (picturesData.findIndex((picture) => picture.id === pictureId));

  const { data, isLoading } = api.get.useQuery(
    `/${model}/${modelId}/pictures`,
    null,
    {
      onSuccess: (response) => {
        if (currentPictureIndex > -1) {
          const pictureIndex = calculatePictureIndex(response.pictures, selectedPicture.id);
          /* istanbul ignore next */
          if (pictureIndex === -1) {
            if (response.pictures.length > currentPictureIndex) {
              setSelectedPicture(response.pictures[currentPictureIndex]);
            } else if (response.pictures.length > 0) {
              setSelectedPicture(response.pictures[response.pictures.length]);
            } else {
              setSelectedPicture(null);
              setCurrentPictureIndex(-1);
            }
          }
        }
      },
    },
  );

  const pictures = data ? extractPictures(data) : null;

  const onPictureChange = (picture) => {
    if (picture && (picture.id !== selectedPicture?.id)) {
      setSelectedPicture(picture);
      setCurrentPictureIndex(calculatePictureIndex(pictures, picture.id));
    }
  };

  const updatePicturesAndFileStorage = async () => {
    queryClient.removeQueries({ queryKey: [`/${model}/${modelId}/pictures`] });
    await subscription.refresh();
    setCurrentFileStorage(subscription.usage?.file_storage || 0);
  };

  const selectedPictureId = selectedPicture ? selectedPicture.id : pictures?.[0]?.id;
  const { mutate: deletePicture, isLoading: isDeletingPicture } = api.delete.useMutation(
    `/${model}/${modelId}/pictures/${selectedPictureId}`,
    {
      onSuccess: async () => {
        await updatePicturesAndFileStorage();
      },
    },
  );

  const onConfirmDelete = async () => {
    deletePicture();
  };

  const {
    mutateAsync: uploadPicture, isLoading: isUploadingPicture,
  } = api.upload.useMutation(
    `/${model}/${modelId}/pictures`,
    {
      onSuccess: async () => {
        await updatePicturesAndFileStorage();
      },
      onError: (error) => {
        throw error;
      },
    },
  );

  const uploadPictures = async (files) => {
    const rejectedFiles = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        file.uploadError = 'incompatible_type_error';
        rejectedFiles.push(file);
      } else {
        // eslint-disable-next-line no-await-in-loop
        const compressedImageFile = await scaleImageFile(file, 1200, 0.8);
        if (compressedImageFile.size > MAX_PICTURE_SIZE) {
          file.uploadError = 'size_limit_exceeded';
          rejectedFiles.push(file);
        } else if (compressedImageFile.size + currentFileStorage > fileStorageLimit) {
          file.uploadError = 'storage_limit_exceeded';
          rejectedFiles.push(file);
        } else {
          try {
            // eslint-disable-next-line no-await-in-loop
            await uploadPicture({ image: compressedImageFile });
            setCurrentFileStorage(currentFileStorage + compressedImageFile.size);
          } catch (error) {
            file.uploadError = error.message;
            rejectedFiles.push(file);
          }
        }
      }
    }
    setRejectFiles(rejectedFiles);
  };

  const onDrop = async (acceptedFiles) => {
    const acceptedFilesWithPreview = acceptedFiles.map((file) => Object.assign(file, {
      preview: URL.createObjectURL(file),
    }));

    await uploadPictures(acceptedFilesWithPreview);
  };

  const contextHelper = isFileStorageExceeded ? (
    <FileStorageLimitHelper />
  ) : null;

  const spinner = (<Loader loading={isLoading || isUploadingPicture || isDeletingPicture} withText={false} className="pictures-spinner" />);

  const pictureDelete = pictures && pictures.length > 0 ? (
    <div className="picture-delete">
      <DeleteActionButton
        id="delete-picture"
        modalTitle={intl.formatMessage({ id: 'edit_pictures.delete.modal_title' })}
        modalMessage={intl.formatMessage({ id: 'edit_pictures.delete.modal_message' })}
        onConfirm={onConfirmDelete}
      />
    </div>
  ) : null;

  const rejectedFilesRendered = rejectFiles ? rejectFiles.map((file) => {
    switch (file.uploadError) {
      case 'size_limit_exceeded':
        file.uploadErrorMessage = intl.formatMessage({ id: 'edit_pictures.size_error' });
        break;
      case 'storage_limit_exceeded':
        file.uploadErrorMessage = intl.formatMessage({ id: 'edit_pictures.size_exceeds_limit' });
        break;
      default:
        file.uploadErrorMessage = intl.formatMessage({ id: 'edit_pictures.incompatible_type_error' });
    }
    return (
      <li key={file.name}>
        <strong>{file.name}</strong>
        {`: ${file.uploadErrorMessage}`}
      </li>
    );
  }) : null;

  const rejectionError = (rejectFiles && rejectFiles.length > 0) ? (
    <div className="pictures-rejection">
      <InputAlert
        message={intl.formatMessage({ id: 'edit_pictures.dropzone.rejection' })}
        type={AlertType.ERROR}
      >
        <ul>{rejectedFilesRendered}</ul>
      </InputAlert>
      <SubscriptionLimitClue />
    </div>
  ) : null;

  return pictures ? (
    <>
      <div className="pictures-header">
        <h2><FormattedMessage id="edit_pictures.header" /></h2>
        <div>{contextHelper}</div>
      </div>
      <div className="edit-pictures">
        <PictureGallery
          id="picture-gallery"
          pictures={pictures}
          placeholder={placeholder}
          onPictureChange={onPictureChange}
        >
          {pictureDelete}
          {spinner}
        </PictureGallery>
        <FilesUpload
          id="pictures-upload"
          classNamePrefix="picture"
          dropzoneInfoPrefix="edit_pictures"
          onDrop={onDrop}
        />
      </div>
      {rejectionError}
    </>
  ) : null;
}

EditPictures.propTypes = {
  model: PropTypes.string.isRequired,
  modelId: PropTypes.number.isRequired,
  placeholder: PropTypes.string,
  session: sessionShape.isRequired,
  subscription: subscriptionShape.isRequired,
};

export default withSession(withSubscription(EditPictures));
