import PropTypes from 'prop-types';
import React from 'react';
import { MenuItem } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import {
  NavigationBar,
  NavigationItem,
  NavigationDropdownItem,
  navigateTo,
  locationShape,
  sessionShape,
  withSession,
  NavigationDemoDataBanner,
  NavigationPermissionRequestsBanner,
} from 'lcm-iot-commons';

export function Navigation({
  session, location, showNavigationItems,
}) {
  const loggedIn = session?.authenticated;

  const applicationSpecifics = session?.applicationSpecifics;

  const menuDropdownSubscriptions = (
    <NavigationDropdownItem
      id="navigation.subscription_dropdown"
      active={/^\/app\/id\/subscriptions*/}
      translationKey="navigation.subscriptions"
    >
      <MenuItem
        id="navigation.services"
        target="/subscriptions/services"
        active={location && location.pathname === '/app/id/subscriptions/services'}
      >
        <FormattedMessage id="navigation.subscriptions.netilion_services" />
      </MenuItem>
      <MenuItem
        id="navigation.connect"
        target="/subscriptions/connect"
        active={location && location.pathname === '/app/id/subscriptions/connect'}
      >
        <FormattedMessage id="navigation.subscriptions.netilion_connect" />
      </MenuItem>
    </NavigationDropdownItem>
  );
  const menuItemEdgeDevices = (
    <MenuItem active={location && location.pathname === '/app/id/edge_devices'} onClick={() => navigateTo('/edge_devices')}>
      <FormattedMessage id="navigation.edge_devices" />
    </MenuItem>
  );
  const navItemEdgeDevice = (<NavigationItem active={/^\/app\/id\/edge_devices.*/} translationKey="navigation.edge_devices" target="/edge_devices" />);

  const showAllObjects = applicationSpecifics?.show_all_objects;

  const allObjects = showAllObjects ? (
    <NavigationItem
      active={/^\/app\/id\/(nodes).*/}
      translationKey="navigation.all_objects"
      target="/nodes"
    />
  ) : null;

  const navBar = loggedIn ? (
    <>
      <NavigationBar
        name="ID"
        mobileName="ID"
        location={location}
        showNavigationItems={showNavigationItems}
        showSupportItem={session.subscriptionType === 'paid'}
        showDemoData
      >
        <NavigationItem
          active={/^\/app\/id(\/?$|\/profile.*)/}
          translationKey="navigation.profile"
          target="/profile"
        />
        {allObjects}
        <NavigationItem
          active={/^\/app\/id\/(administration|tenants|usergroups).*/}
          translationKey="navigation.administration"
          target="/administration"
        />
        {menuDropdownSubscriptions}
        {navItemEdgeDevice}
        {menuItemEdgeDevices}

      </NavigationBar>
      <div className="navigation-banner-container">
        <NavigationPermissionRequestsBanner id="open-permission-banner" />
        <NavigationDemoDataBanner id="demo-data-banner" />
      </div>
    </>
  ) : (
    <NavigationBar name="Netilion" mobileName="Netilion" location={location} showNavigationItems={showNavigationItems} />
  );

  return navBar;
}

Navigation.propTypes = {
  session: sessionShape,
  location: locationShape,
  showNavigationItems: PropTypes.bool,
};

Navigation.defaultProps = {
  session: undefined,
  location: undefined,
  showNavigationItems: undefined,
};

export default withSession(Navigation);
