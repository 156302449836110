import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  DeleteActionButton,
  ActionButton,
} from '../ActionBar';
import {
  formatDate,
  formatDateTime,
  isSomething,
  isEmpty,
  exportDataStatusIconClassName,
} from '../../utils';

import { dataExportShape } from '../../shapes/dataExportShape';
import DataExportStatus from '../../constants/DataExportStatus';
import {
  withSession, trackingShape,
  withTracking,
} from '../../context';
import { useApi } from '../../hooks';

export function DataExportItem({
  session,
  dataExport,
  tracking,
}) {
  const api = useApi();
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { mutate: handleDeleteDataExport } = api.delete.useMutation(`/users/current/data_exports/${dataExport.id}`, {
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['/users/current/data_exports'] }),
  });

  const handleDownload = () => {
    tracking.trackEvent('DataExportItem-clicked');
    window.open(`${dataExport.downloadHref}?access_token=${session.token}`, '_self');
  };

  const finishedAt = dataExport?.finishedAt ? (formatDateTime(dataExport.finishedAt, intl)) : ('-');
  const availableUntil = dataExport?.availableUntil ? (formatDate(dataExport.availableUntil, intl)) : ('-');
  const { serial_number: assetSerialNumber, id: assetId } = dataExport?.asset ?? {};
  const { tag: instrumentationTag, id: tagId } = dataExport?.instrumentation ?? {};
  const { name: nodeName, id: nodeId } = dataExport?.node ?? {};
  const { name: batchName, id: batchId } = dataExport?.batch ?? {};
  const { number: orderNumber, customer_purchase_order_number: customerPurchaseOrderNumber, id: orderId } = dataExport?.purchaseOrder ?? {};
  const name = dataExport && dataExport.type === 'complete' ? '-' : (assetSerialNumber || instrumentationTag || nodeName || batchName || (customerPurchaseOrderNumber ?? orderNumber));
  const id = assetId || tagId || nodeId || batchId || orderId;
  const linkMap = new Map();
  linkMap.set('instrumentation_values', '/app/value/instrumentations/');
  linkMap.set('batch_values', '/app/value/batches/');
  linkMap.set('node_documents_recursive', '/app/library/nodes/');
  linkMap.set('asset_documents', '/app/library/assets/');
  linkMap.set('purchase_order_documents', '/app/library/orders/');
  const baseLink = linkMap.get(dataExport.type);

  return (
    <tr>
      <td><div className={exportDataStatusIconClassName(dataExport.status)} /></td>
      <td><FormattedMessage id={`data_export.type.${dataExport.type}`} /></td>
      <td className="hidden-xs">{baseLink ? <a href={`${baseLink}${id}`}>{name}</a> : name}</td>
      <td className="hidden-xs">{formatDateTime(dataExport.createdAt, intl)}</td>
      <td className="hidden-xs">{finishedAt}</td>
      <td>{availableUntil}</td>
      <td className="right">
        <ActionButton
          id="download-export-file-button"
          icon="icon-eh-save"
          label="Download"
          defaultLabel={intl.formatMessage({ id: 'button.download' })}
          onClick={handleDownload}
          disabled={!isSomething(dataExport) || dataExport.status !== DataExportStatus.DONE || isEmpty(dataExport.downloadHref)}
        />
        <DeleteActionButton
          id="delete-export-file-button"
          modalTitle={intl.formatMessage({ id: 'export_delete.modal_title' })}
          modalMessage={intl.formatMessage({ id: 'export_delete.modal_message' })}
          disabled={isSomething(dataExport) && dataExport.status === DataExportStatus.IN_PROGRESS}
          onConfirm={() => handleDeleteDataExport()}
        />
      </td>
    </tr>
  );
}

DataExportItem.propTypes = {
  session: PropTypes.shape({
    token: PropTypes.string,
  }),
  dataExport: dataExportShape,
  token: PropTypes.string,
  tracking: trackingShape.isRequired,
};

export default withTracking(withSession(DataExportItem));
