import React, { useState } from 'react';
import {
  withApi,
  withBrowser,
  withNotifier,
  withIntl,
  apiShape,
  browserShape,
  notifierShape,
  intlShape,
  ActionBar,
  Container,
  Heading,
  Loader,
  isSomething,
} from 'lcm-iot-commons';
import UserEmailEditForm from './UserEmailEditForm';
import UserContactDetailsForm from './UserContactDetailsForm';
import { getTimeZone } from '../../utils/timeZones';

export function UserProfileEdit({
  api, browser, notifier, intl,
}) {
  const [userDetails, setUserDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const formUser = (user) => {
    if (!user) return user;

    const formUserObj = {
      id: user.id,
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
      ssoProvider: user.sso_provider,
      timeZone: getTimeZone(user.time_zone),
    };
    if (user.address) {
      formUserObj.company = user.address.company_name;
      formUserObj.streetName = user.address.street;
      formUserObj.streetNumber = user.address.street_number;
      formUserObj.zipCode = user.address.zip_code;
      formUserObj.city = user.address.city;
      formUserObj.regionCode = user.address.region_code;
      formUserObj.billingEmail = user.address.email;
      formUserObj.country = {
        name: user.address.country_name,
        code: user.address.country_code,
      };
      formUserObj.phone = user.address.phone;
      formUserObj.vatNumber = user.address.vat_number;
    } else {
      formUserObj.country = {
        name: undefined,
        code: undefined,
      };
    }
    return formUserObj;
  };

  const loadUserDetails = async () => {
    try {
      const user = await api.get('/users/current?include=userroles,demo_data');
      setUserDetails(formUser(user));
    } catch (error) {
      notifier.showError(api.translateError(error));
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    loadUserDetails();
  }, []);

  const isSsoUser = isSomething(userDetails?.ssoProvider);

  const handleSubmit = async (values, actions) => {
    try {
      const patchUserDetails = {
        first_name: values.firstName,
        last_name: values.lastName,
        time_zone: values.timeZone ? values.timeZone.identifier : null,
        address: {
          company_name: values.company,
          street: values.streetName,
          street_number: values.streetNumber,
          zip_code: values.zipCode,
          city: values.city,
          region_code: values.regionCode,
          country_code: values.country?.code,
          phone: values.phone,
          vat_number: values.vatNumber,
          email: values.billingEmail,
        },
      };
      await api.patch(`/users/${userDetails.id}`, patchUserDetails);
      notifier.showSuccess(intl.formatMessage({ id: 'edit_contact_details.success_notification' }));
      actions.resetForm(values);
      browser.navigateTo('/profile');
    } catch (error) {
      if (error?.errors?.some((e) => e.type === 'vat_number')) {
        actions.setErrors({ vatNumber: intl.formatMessage({ id: 'api.error.tax_address.invalid_input' }) });
        return;
      }
      notifier.showError(api.translateError(error));
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleOnSaveClick = async (values, actions) => {
    await handleSubmit(values, actions, false);
  };

  const renderContent = isLoading ? null : (
    <>
      <div id="user-profile-edit-contact-details-group">
        <Heading
          level={2}
          id="user-profile-edit-contact-details-header"
          title={intl.formatMessage({ id: 'edit_contact_details.header' })}
        />
        <UserContactDetailsForm
          id="user-profile-contact-details-form"
          initialValues={userDetails}
          ssoUser={isSsoUser}
          onSubmit={handleOnSaveClick}
        />
      </div>
      {isSsoUser ? null : (
        <div id="user-profile-email-edit-group">
          <Heading
            level={2}
            id="change-current-email-header"
            title={intl.formatMessage({ id: 'change_current_email.header' })}
          />
          <UserEmailEditForm id="change-current-email-form" />
        </div>
      )}
    </>
  );

  return (
    <Container>
      <ActionBar>
        <Heading id="user-profile-edit-header" title={intl.formatMessage({ id: 'user_profile_edit.header' })} />
      </ActionBar>
      {renderContent}
      <Loader loading={isLoading} />
    </Container>
  );
}

UserProfileEdit.propTypes = {
  api: apiShape.isRequired,
  browser: browserShape.isRequired,
  notifier: notifierShape.isRequired,
  intl: intlShape.isRequired,
};

export default withApi(withNotifier(withIntl(withBrowser(UserProfileEdit))));
