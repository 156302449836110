import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Column,
  CancelButton,
  Form,
  intlShape,
  SubmitButton,
  TextInput,
  withIntl,
} from 'lcm-iot-commons';
import { Formik } from 'formik';

const allow6Numbers = /^\d{6}$/;

export function MultiFactorAuthenticationRecoveryCodesForm({ onSubmit, onCancel, intl }) {
  const validateForm = (values) => {
    const errors = {};
    if (!(values.otpCodeToken)) {
      errors.otpCodeToken = intl.formatMessage({ id: 'mfa.code_mandatory' });
    } else if (!allow6Numbers.test(values.otpCodeToken)) {
      errors.otpCodeToken = intl.formatMessage({ id: 'mfa.code_format' });
    }
    return errors;
  };

  const renderForm = (formProps) => {
    const { isSubmitting } = formProps;
    return (
      <Form {...formProps}>
        <Row>
          <Column md={12}>
            <TextInput
              {...formProps}
              id="mfa-code"
              name="otpCodeToken"
              otpCodeToken
              label={intl.formatMessage({ id: 'mfa.code' })}
              placeholder={intl.formatMessage({ id: 'mfa.code_placeholder' })}
              required
            />
          </Column>
        </Row>
        <Row>
          <Column md={12}>
            <div className="modal-btn-group">
              <SubmitButton
                id="mfa-change-submit"
                text={intl.formatMessage({ id: 'button.confirm' })}
                fetching={isSubmitting}
                intl={intl}
                disabled={!formProps.dirty}
              />
              <CancelButton id="mfa-change-cancel" handleOnClick={onCancel} disabled={isSubmitting} intl={intl} />
            </div>
          </Column>
        </Row>
      </Form>
    );
  };

  return (
    <Formik
      id="change-mfa-form"
      onSubmit={onSubmit}
      validate={validateForm}
      render={renderForm}
    />
  );
}

MultiFactorAuthenticationRecoveryCodesForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default withIntl(MultiFactorAuthenticationRecoveryCodesForm);
