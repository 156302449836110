import React, { useState } from 'react';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import {
  ActionBar,
  AlertType,
  apiErrorsContain,
  apiShape,
  browserShape,
  ButtonGroup,
  CancelButton,
  Column,
  CONFIGURATION,
  ConflictError,
  Container,
  Form,
  Heading,
  InputAlert,
  intlShape,
  isEmpty,
  Loader,
  NotFoundError,
  notifierShape,
  Row,
  SubmitButton,
  TextInput,
  userShape,
  withApi,
  withBackend,
  withBrowser,
  withFlipper,
  withNotifier,
  withUser,
  withIntl,
  handleFormikValueChange,
  SelectBox,
  htmlFormat,
  htmlLink,
} from 'lcm-iot-commons';
import PropTypes from 'prop-types';
import { TenantTypeahead } from 'lcm-iot-commons/client/lib/components/Tenants/TenantTypeahead';
import { UserGroupTypeahead } from 'lcm-iot-commons/client/lib/components/UserGroups/UserGroupTypeahead';
import { ContextualHelp } from 'lcm-iot-commons/client/lib/components/Contextuals/ContextualHelp';

export function EdgeDeviceCreate({
  api, browser, intl, notifier, user,
}) {
  const [errors, setErrors] = useState();
  const [formData, setFormData] = useState();

  const loadData = async () => {
    const subscriptionNames = [];

    const addonsResponse = await api.get('/add_ons?scope=USABLE&add_on_type=connectivity&product_code=uplink&include=addonable_name');
    addonsResponse.add_ons.forEach((addon) => {
      if (addon.quantity > addon.assigned_edge_devices_count) {
        subscriptionNames.push({ name: addon.addonable.name, id: addon.id });
      }
    });
    return subscriptionNames;
  };
  async function fetchData() {
    const subscriptionsWithFreeAddons = await loadData();
    setFormData({
      serialNumber: '',
      tenant: null,
      usergroup: null,
      subscriptions: subscriptionsWithFreeAddons,
      selectedSubscription: null,
    });
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleOnSubmit = async (values, actions) => {
    try {
      let { tenant } = values;
      let { usergroup } = values;
      if (values.tenant?.new === true) {
        tenant = { id: (await api.post('/tenants', { name: values.tenant.name })).id };
      }
      if (values.usergroup?.new === true) {
        usergroup = { id: (await api.post('/usergroups', { name: values.usergroup.name })).id };
      }

      await api.patch('/edm/edge_devices/link', {
        serial_number: values.serialNumber, tenant: { id: tenant.id }, usergroup: { id: usergroup.id }, add_on: { id: values.selectedSubscription.id },
      });
      setErrors([]);
      notifier.showSuccess(intl.formatMessage({ id: 'edge_device_create.success_message' }));
      browser.navigateTo('/edge_devices');
    } catch (error) {
      if (error.contains('association_not_found', 'usergroup')) {
        setErrors([{ type: 'association_not_found_usergroup' }]);
      } else if (error.contains('taken', 'name') && values.tenant.id === undefined) {
        actions.setErrors({ tenant: intl.formatMessage({ id: 'api.error.tenant.taken' }) });
      } else if (error instanceof NotFoundError) {
        setErrors([{ type: 'not_found' }]);
      } else if (error instanceof ConflictError) {
        setErrors([{ type: 'taken' }]);
      } else {
        setErrors([{ type: 'api_error' }]);
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  const validateForm = (values) => {
    const formErrors = {};
    if (values.selectedSubscription?.id === undefined) {
      formErrors.subscription = intl.formatMessage({ id: 'edge_device_create.error.subscription' });
      setErrors();
    }
    if (isEmpty(values.serialNumber) || isEmpty(values.serialNumber.trim())) {
      formErrors.serialNumber = intl.formatMessage({ id: 'validation.serial_number.mandatory' });
      setErrors();
    }
    if (values.tenant?.id === undefined && !values.tenant?.new === true) {
      formErrors.tenant = intl.formatMessage({ id: 'validation.product.tenant.mandatory' });
      setErrors();
    }
    if (values.usergroup?.id === undefined && !values.usergroup?.new === true) {
      formErrors.usergroup = intl.formatMessage({ id: 'validation.user_group.mandatory' });
      setErrors();
    }
    return formErrors;
  };

  const renderForm = (formProps) => {
    const { isSubmitting } = formProps;

    const contextHelperUserGroup = (
      <ContextualHelp title={intl.formatMessage({ id: 'edit_permissions.user_groups' })} interactive>
        {intl.formatMessage({ id: 'edge_device_create.user_group.help_text' })}
      </ContextualHelp>
    );

    const contextHelperTenant = (
      <ContextualHelp title={intl.formatMessage({ id: 'label.tenant' })} interactive>
        {intl.formatMessage({ id: 'edge_device_create.tenant.help_text' })}
      </ContextualHelp>
    );

    return (
      <Form {...formProps}>
        <SelectBox
          {...formProps}
          id="subscription"
          name="subscription"
          label={intl.formatMessage({ id: 'edge_device_create.subscription' })}
          options={formData.subscriptions}
          handleChange={(event) => handleFormikValueChange({
            ...formProps,
            name: 'selectedSubscription',
          }, event.target.value)}
          required
        />
        <TextInput
          {...formProps}
          id="edge-device-serial-number"
          name="serialNumber"
          label={intl.formatMessage({ id: 'label.serial_number' })}
          required
        />
        <TenantTypeahead
          {...formProps}
          id="edge-device-tenant"
          data-testid="tenant-typehead"
          name="tenant"
          label={intl.formatMessage({ id: 'label.tenant' })}
          canCreate
          required
          user={user}
          intl={intl}
          notifier={notifier}
          api={api}
          help={contextHelperTenant}
        />
        <UserGroupTypeahead
          {...formProps}
          id="edge-device-user-group"
          name="usergroup"
          data-testid="usergroup-typehead"
          label={intl.formatMessage({ id: 'edit_permissions.user_groups' })}
          intl={intl}
          notifier={notifier}
          api={api}
          required
          canCreate
          help={contextHelperUserGroup}
        />
        <ButtonGroup>
          <SubmitButton
            id="edge-device-add-submit"
            fetching={isSubmitting}
            text={intl.formatMessage({ id: 'edge_device_create.submit_button' })}
            intl={intl}
          />
          <CancelButton id="edge-device-add-cancel" disabled={isSubmitting} intl={intl} />
        </ButtonGroup>
      </Form>
    );
  };

  const renderErrorMessage = () => {
    const errorReasons = [];

    if (apiErrorsContain(errors, 'not_found')) {
      errorReasons.push((
        <li id="not-found" key="not_found">
          <FormattedMessage id="edge_device_create.errors.not_found" />
        </li>
      ));
    } else if (apiErrorsContain(errors, 'association_not_found_usergroup')) {
      errorReasons.push((
        <li id="taken" key="taken">
          <FormattedMessage
            id="edge_device_create.errors.assotiation_usergroup"
          />
        </li>
      ));
    } else if (apiErrorsContain(errors, 'taken')) {
      errorReasons.push((
        <li id="taken" key="taken">
          <FormattedMessage
            id="edge_device_create.errors.conflict"
            values={{
              ...htmlFormat,
              a: htmlLink({ href: `${CONFIGURATION}/support/tickets` }),
            }}
          />
        </li>
      ));
    } else if (apiErrorsContain(errors, 'api_error')) {
      errorReasons.push((
        <li id="api-error" key="api_error">
          <FormattedMessage
            id="edge_device_create.errors.unknown"
            values={{
              ...htmlFormat,
              a: htmlLink({ href: `${CONFIGURATION}/support/tickets` }),
            }}
          />
        </li>
      ));
    }

    return (errorReasons.length > 0) ? (
      <div id="edge-device-add-error" className="space-before">
        <InputAlert id="edge-device-add-error-message" message={intl.formatMessage({ id: 'edge_device_create.errors.general' })} type={AlertType.ERROR}>
          <ul id="edge-device-add-error-reasons">
            {errorReasons}
          </ul>
        </InputAlert>
      </div>
    ) : null;
  };

  return user ? (
    <Loader loading={!formData}>
      <Container>
        <Row id="edge-device-add-header">
          <Column>
            <ActionBar>
              <Heading title={intl.formatMessage({ id: 'edge_device_create.header' })} />
            </ActionBar>
          </Column>
        </Row>
        <Row id="edge-device-add-form">
          <Column sm="6">
            {formData ? (
              <Formik
                onSubmit={handleOnSubmit}
                validate={validateForm}
                initialValues={{ type: formData.initialType }}
                render={renderForm}
              />
            ) : null}
          </Column>
        </Row>
        <Row id="edge-device-add-errors">
          <Column sm="6">
            {renderErrorMessage()}
          </Column>
        </Row>
      </Container>
    </Loader>
  ) : null;
}

EdgeDeviceCreate.propTypes = {
  api: apiShape.isRequired,
  browser: browserShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  user: userShape,
  flipper: PropTypes.shape({
    edgeDeviceLateBinding: PropTypes.bool,
  }).isRequired,
};

EdgeDeviceCreate.defaultProps = {
  user: undefined,
};

export default withFlipper(withApi(withBackend(withUser(withBrowser(withNotifier(withIntl(EdgeDeviceCreate)))))));
