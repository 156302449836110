import { useIntl } from 'react-intl';
import { convertSpecificationKeyToLabel } from './specificationUtils';

export const getSpecificationChips = (specifications) => {
  const intl = useIntl();

  const items = Object.keys(specifications || {})
    .filter((key) => specifications[key].ui_visible !== false)
    ?.map((key) => {
      const label = convertSpecificationKeyToLabel(key);
      const { value } = specifications[key];
      return {
        name: `${label}: ${value}`,
        id: `${label}: ${value}`,
      };
    });

  if (items?.length === 0) return [{ name: intl.formatMessage({ id: 'search.results.no_specifications' }), id: 'specification-chip-undefined' }];

  return items;
};

export const getAssignedNodesChips = (nodes) => {
  const intl = useIntl();

  const items = nodes?.map((node) => ({ name: `${node.name}`, link: `/nodes/${node.id}`, id: `${node.name}-${node.id}` })) || [];

  if (items.length === 0) return [{ name: intl.formatMessage({ id: 'search.results.no_assigned_nodes' }), id: 'assigned-nodes-chip-undefined' }];

  return items;
};
