import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import Skeleton from 'react-loading-skeleton';
import {
  ActionBar,
  ActionBarButtons,
  ActionDropdownButton,
  DeleteActionButton,
  EditActionButton,
  DetailsActionButton,
} from '../ActionBar';

import {
  accessRightsShape,
  nodeShape,
} from '../../shapes';

import {
  rulesShape,
  subscriptionShape,
  withRules,
  withSubscription,
} from '../../context';

import {
  url,
} from '../../utils';

import Heading from '../Heading';
import AllObjectsBadge from './AllObjectsBadge';

export function AllObjectsHeader({
  accessRights,
  additionalCreateMenuItems,
  isLoading,
  node,
  rules,
  subscription,
  onConfirmDelete,
  isParentNode,
}) {
  const intl = useIntl();
  const { id: nodeId } = useParams();
  const navigate = useNavigate();

  const handleOnCreateClick = (key) => {
    switch (key) {
      case 'create_asset':
        navigate(nodeId ? url(`/nodes/${nodeId}/assets/create`) : url('/assets/create'));
        break;
      case 'create_node':
        navigate(nodeId ? url(`/nodes/${nodeId}/create`) : url('/nodes/create'));
        break;
      case 'create_tag':
        navigate(nodeId ? url(`/nodes/${nodeId}/instrumentations/create`) : url('/instrumentations/create'));
        break;
      case 'scan_asset':
        navigate(nodeId ? url(`/nodes/${nodeId}/assets/create_scan`) : url('/assets/create_scan'));
        break;
        /* istanbul ignore next */
      default:
        break;
    }
  };

  const editTarget = nodeId ? `/nodes/${nodeId}/edit` : '/404';
  const detailsTarget = nodeId ? `/nodes/${nodeId}/details` : '/404';

  const nodeDetailsButton = node ? (
    <DetailsActionButton
      id="details-node-button"
      target={detailsTarget}
    />
  ) : null;

  const editButton = node ? (
    <EditActionButton
      id="edit-node-button"
      disabled={!accessRights.canUpdate || !rules.node(node).get('editable')}
      target={editTarget}
    />
  ) : null;

  const deleteButton = node ? (
    <DeleteActionButton
      id="delete-node-button"
      disabled={isParentNode || !accessRights.canDelete || isLoading}
      modalTitle={intl.formatMessage({ id: 'node.actions.delete.modal_title' })}
      modalMessage={intl.formatMessage({ id: 'node.actions.delete.modal_message' })}
      onConfirm={onConfirmDelete}
    />
  ) : null;

  const scanAssetButton = rules.application().get('showScanAssetButton') ? (
    <MenuItem
      className="icon icon-eh-show-less"
      eventKey="scan_asset"
      id="scan-asset-menu-item"
      disabled={subscription?.asset_quota > 0 && subscription?.number_assigned_assets >= subscription?.asset_quota}
      onSelect={handleOnCreateClick}
    >
      <FormattedMessage id="dropdown_scan.asset" />
    </MenuItem>
  ) : null;

  const loader = (<Skeleton width={80} containerTestId="allObjectsHeader-skeleton" />);

  const title = () => {
    if (!!nodeId && isLoading) return loader;
    return node?.name ?? intl.formatMessage({ id: 'nodes.header' });
  };

  return (
    <ActionBar>
      <Heading level={1} className="heading-badge" title={title()}>
        <AllObjectsBadge />
      </Heading>
      <ActionBarButtons>
        {nodeDetailsButton}
        {deleteButton}
        {editButton}
        <ActionDropdownButton id="create-button" disabled={node ? !accessRights.canUpdate : false} icon="icon-eh-new" title={intl.formatMessage({ id: 'button.create' })}>
          <MenuItem
            className="icon icon-eh-show-less"
            eventKey="create_asset"
            id="create-asset-menu-item"
            disabled={subscription?.asset_quota > 0 && subscription?.number_assigned_assets >= subscription?.asset_quota}
            onSelect={handleOnCreateClick}
          >
            <FormattedMessage id="dropdown_create.asset" />
          </MenuItem>
          {scanAssetButton}
          <MenuItem
            className="icon icon-eh-show-less"
            eventKey="create_tag"
            id="create-tag-menu-item"
            onSelect={handleOnCreateClick}
          >
            <FormattedMessage id="dropdown_create.tag" />
          </MenuItem>
          {additionalCreateMenuItems}
          <MenuItem
            className="icon icon-eh-show-less"
            eventKey="create_node"
            id="create-node-menu-item"
            disabled={node && !accessRights.canUpdate}
            onSelect={handleOnCreateClick}
          >
            <FormattedMessage id="dropdown_create.node" />
          </MenuItem>
        </ActionDropdownButton>
      </ActionBarButtons>
    </ActionBar>
  );
}

AllObjectsHeader.propTypes = {
  accessRights: accessRightsShape,
  additionalCreateMenuItems: PropTypes.node,
  isLoading: PropTypes.bool.isRequired,
  node: nodeShape,
  rules: rulesShape.isRequired,
  subscription: subscriptionShape.isRequired,
  onConfirmDelete: PropTypes.func,
  isParentNode: PropTypes.bool,
};

export default withRules(withSubscription(AllObjectsHeader));
