import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Column, Row } from './Grid';

const LoaderContext = React.createContext();

const loaderShape = PropTypes.func;

function Loading({ id, className, withText }) {
  return (
    <div id={id} data-testid={id} className={`loader ${className}`}>
      {withText && (<span><FormattedMessage id="general.loading" /></span>)}
      <div className="icon-spinner-dark" />
    </div>
  );
}

Loading.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  withText: PropTypes.bool,
};

function Loader({
  id, loading, children, className = '', withText = true,
}) {
  const [loadingState, setLoadingState] = useState(0);
  const setLoading = (state) => {
    if (state) {
      setLoadingState(loadingState + 1);
    } else {
      setLoadingState(Math.max(loadingState - 1, 0));
    }
  };

  if (children) {
    return (
      <>
        <LoaderContext.Provider value={setLoading}>
          {children}
        </LoaderContext.Provider>
        {loadingState > 0 || loading ? (
          <Container>
            <Row>
              <Column>
                <div className="space-before" />
                <Loading id={id} className={className} withText={withText} />
              </Column>
            </Row>
          </Container>
        ) : null}
      </>
    );
  }

  return loading ? <Loading id={id} className={className} withText={withText} /> : null;
}

const withLoader = (ChildComponent) => {
  function ConnectedComponent(props) {
    return (
      <LoaderContext.Consumer>
        {(context) => <ChildComponent {...props} setLoading={context} />}
      </LoaderContext.Consumer>
    );
  }
  return ConnectedComponent;
};

Loader.propTypes = {
  id: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  withText: PropTypes.bool,
};

export {
  loaderShape,
  withLoader,
};

export default Loader;
