export const getEdgeDeviceImageUrl = (edgeDeviceCode, size) => {
  switch (edgeDeviceCode) {
    case 'sgc500':
    case 'sgc500_powerbox':
    case 'sgc500_karbon_300':
    case 'sgc500_karbon_300_c20':
    case 'sgc500_karbon_300_hbv_flow':
      return `https://portal.endress.com/webapp/picturepool/${size}/0/SGC500.jpg`;
    case 'SGC400':
      return `https://portal.endress.com/webapp/picturepool/${size}/0/SGC400.jpg`;
    case 'sgc200':
      return `https://portal.endress.com/webapp/picturepool/${size}/0/SGC200.jpg`;
    default:
      return undefined;
  }
};

export const getFieldGatewayImageUrl = (fieldGatewayCode, size) => {
  switch (fieldGatewayCode) {
    case 'cm44modbustcp':
      return `https://portal.endress.com/webapp/picturepool/${size}/0/CM444.jpg`;
    case 'nxa820':
      return `https://portal.endress.com/webapp/picturepool/${size}/0/NXA820.jpg`;
    case 'sfg250':
      return `https://portal.endress.com/webapp/picturepool/${size}/0/SFG250.jpg`;
    case 'sfg500':
      return `https://portal.endress.com/webapp/picturepool/${size}/0/SFG500.jpg`;
    case 'swg50':
      return `https://portal.endress.com/webapp/picturepool/${size}/0/SWG50.jpg`;
    case 'swg70':
      return `https://portal.endress.com/webapp/picturepool/${size}/0/SWG70.jpg`;
    default:
      return undefined;
  }
};
