/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { intlShape } from '../../shapes';
import { AlertType } from '../../constants';
import { isNotEmpty } from '../../utils';
import { withIntl } from '../../wrappers';

function getIconClassName(type) {
  switch (type) {
    case (AlertType.SUCCESS):
      return 'icon icon3-eh-radio-button-checked text-success';
    case (AlertType.INFO):
      return 'icon lcm-iot-icon-info text-info';
    case (AlertType.WARNING):
      return 'icon icon-eh-warning-1 text-warning';
    default:
      return 'icon icon-eh-warning-1 text-danger';
  }
}

export function InputAlert({
  children, message, intl, className, type,
}) {
  const [extendedMessageVisible, setExtendedMessageVisible] = useState(false);

  if (isNotEmpty(message)) {
    if (children) {
      const detailsLink = extendedMessageVisible
        ? intl.formatMessage({ id: 'global.hide_details' }) : intl.formatMessage({ id: 'global.show_details' });
      const details = extendedMessageVisible ? children : null;
      return (
        <div id="input-alert" className={`input-alert ${className}`}>
          <small>
            <span className={getIconClassName(type)} />
            <span>
              {message}
              {' ('}
              <a id="show-error-details" onClick={() => setExtendedMessageVisible(!extendedMessageVisible)}>{detailsLink}</a>
              )
            </span>
            {details}
          </small>
        </div>
      );
    }
    return (
      <div className={`input-alert ${className}`}>
        <small>
          <span className={getIconClassName(type)} />
          <span>{` ${message}`}</span>
        </small>
      </div>
    );
  }
  return null;
}

InputAlert.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.node,
  intl: intlShape.isRequired,
};

export default withIntl(InputAlert);
