import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { camelCase } from 'lodash-es';
import {
  withConfiguration,
} from '../context/ConfigurationContext';

import ApiClient from '../api/ApiClient';
import Unknown from '../components/Errors/Unknown';

let cache;
const withFlipper = (ChildComponent) => {
  const WrappedComponent = withConfiguration((properties) => {
    const {
      configuration,
      innerRef,
      testFlipper,
      ...props
    } = properties;
    const [flipper, setFlipper] = useState(cache);
    const [unknownError, setUnknownError] = useState(false);

    useEffect(() => {
      if (testFlipper || configuration.testFlipper) {
        setFlipper(testFlipper || configuration.testFlipper);
        return;
      }
      const loadFlipper = async () => {
        try {
          const apiUrl = configuration.lcmApiUrl;
          const baseUrl = apiUrl.slice(0, apiUrl.length - 3);
          const client = new ApiClient(baseUrl);
          const response = await client.get('/flipper/api/features');
          cache = response?.features.reduce((features, feature) => {
            // eslint-disable-next-line no-param-reassign
            features[camelCase(feature.key)] = feature.state === 'on';
            return features;
          }, {});
          setFlipper(cache);
        } catch (error) {
          setUnknownError(true);
        }
      };

      if (!flipper) {
        loadFlipper();
      }
    }, []);

    if (flipper) {
      return (<ChildComponent {...props} flipper={flipper} ref={innerRef} />);
    }
    if (unknownError) {
      return (<Unknown {...props} />);
    }
    return null;
  });

  WrappedComponent.propTypes = {
    configuration: PropTypes.shape({
      lcmApiUrl: PropTypes.string,
    }),
    testFlipper: PropTypes.shape({}),
  };

  const ForwardRefWrappedComponent = forwardRef((props, ref) => (
    <WrappedComponent {...props} innerRef={ref} />
  ));

  ForwardRefWrappedComponent.displayName = ChildComponent.displayName || ChildComponent.name;

  return ForwardRefWrappedComponent;
};

export default withFlipper;
