import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  Row,
  Column,
  Container,
  Loader,
  Heading,
  ActionBar,
  EditActionButton,
  ActionBarButtons,
  DeleteActionButton,
  Details,
  DetailsItem,
  DetailsPicture,
  DetailsHeaderItem,
  PictureGallery,
  apiShape,
  intlShape,
  browserShape,
  notifierShape,
  accessRightsShape,
  userShape,
  withApi,
  withBrowser,
  withFlipper,
  withSession,
  withNotifier,
  withIntl,
  withUser,
  withAccessRights,
  parseISO8601Date,
  pullBooleanFromSpec,
  FormattedRelativeTime,
  pullValueFromSpec,
  More,
} from 'lcm-iot-commons';

import {
  edgeDeviceIconClassName,
  edgeDeviceStatus,
  edgeDeviceStatusIconClassName,
  fieldGatewayEnableStatusIconClassName,
  lcmIotIconCirculeRedGreenIcon,
  lastSeenStatus,
} from '../../utils/statusUtils';

import FieldGateways from './FieldGateways';
import NetworkInterfaces from './NetworkInterfaces';
import EdgeDeviceApplications from './EdgeDeviceApplications';
import UpdateSchedules from './UpdateSchedules';
import { getVersionsWithCertainStatus } from '../../utils/edgeDeviceUpdateSchedulesUtils';
import { getEdgeDeviceImageUrl } from './PicturePoolUrl';

import {
  EdgeDeviceTypes,
} from './EdgeDeviceConstants';

const convertSecondsToMinutes = (sec) => {
  if (!sec) { return null; }
  return Math.round(sec / 60);
};

const isLogLevelEnabled = (edgeDevice) => edgeDevice
 && edgeDevice.type?.code !== EdgeDeviceTypes.SGC200;

const areFieldGatewaysEnabled = (edgeDevice) => edgeDevice
 && edgeDevice.type?.code !== EdgeDeviceTypes.SGC200;

const areNetworkInterfacesEnabled = (edgeDevice) => edgeDevice
 && edgeDevice.type?.code !== EdgeDeviceTypes.SGC200
 && edgeDevice.type?.code !== EdgeDeviceTypes.SGC500_SW
 && edgeDevice.networkInterfaces.length > 0;

const isEdgeDeviceNotificationsOfflineStatusActive = (statusOfflineValue, userId) => statusOfflineValue.split(';').includes(userId.toString());

export function EdgeDeviceDetails({
  intl,
  accessRights,
  match,
  api,
  notifier,
  browser,
  flipper,
  user,
}) {
  const [edgeDevice, setEdgeDevice] = useState();

  const loadEdgeDevice = async () => {
    try {
      const [edgeJson, fieldGateways, networkInterfaces, edgeDeviceApplications, updateSchedules] = await Promise.all([
        api.get(`/edm/edge_devices/${match.params.id}`, { include: 'status, type, specifications, software_version, add_on, tenant, usergroup' }, false),
        api.getAll(`/edm/edge_devices/${match.params.id}/field_gateways`, { include: 'status, type, specifications, software_version' }),
        api.getAll(`/edm/edge_devices/${match.params.id}/network_interfaces`),
        api.getAll(`/edm/edge_devices/${match.params.id}/applications`, { order_by: 'name' }),
        api.getAll('/edm/update_schedules', { include: 'status, software_version', edge_device_id: match.params.id, order_by: '-updated_at' }),
      ]);
      const data = edgeJson;

      data.last_seen_at = edgeJson.last_seen_at ? parseISO8601Date(edgeJson.last_seen_at) : null;
      data.apply_timestamp = edgeJson.apply_timestamp ? parseISO8601Date(edgeJson.apply_timestamp) : null;
      data.ethernetip_supported = edgeJson.software_version?.ethernetip_supported;
      data.ethernet_ip_active = pullBooleanFromSpec(edgeJson.specifications, ['eh.user_config.ethernet_ip_active']);
      data.data_collection_interval = convertSecondsToMinutes(pullValueFromSpec(edgeJson.specifications, ['eh.user_config.data_collection_interval']));
      data.data_send_interval = convertSecondsToMinutes(pullValueFromSpec(edgeJson.specifications, ['eh.user_config.data_send_interval']));
      data.client_application = edgeJson.api_subscription?.client_application;
      data.tenant = edgeJson.tenant?.name;
      data.usergroup = edgeJson.usergroup?.name;

      data.fieldGateways = fieldGateways?.field_gateways;
      data.networkInterfaces = networkInterfaces?.network_interfaces;
      data.edgeDeviceApplications = edgeDeviceApplications?.edge_device_applications;
      data.updateSchedules = getVersionsWithCertainStatus(updateSchedules?.update_schedules);

      data.edgeDeviceImageUrl = getEdgeDeviceImageUrl(edgeJson.type.code, 500);

      const statusOfflineValue = pullValueFromSpec(edgeJson.specifications, ['eh.user_config.user_notifications.status_offline']);
      data.isEdgeDeviceNotificationsOfflineStatusActive = statusOfflineValue && user?.id && isEdgeDeviceNotificationsOfflineStatusActive(statusOfflineValue, user.id);

      setEdgeDevice(data);
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  React.useEffect(() => {
    loadEdgeDevice();
  }, [match.params.id]);

  const handleOnConfirmDelete = () => {
    try {
      api.patch(`/edm/edge_devices/${match.params.id}/unlink`);
      notifier.showSuccess(intl.formatMessage({ id: 'edge_device.delete.successful' }));
      browser.navigateTo('/edge_devices');
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  const getLastSeenTextColor = (edgeDeviceData) => (edgeDeviceStatus(edgeDeviceData.status.code, edgeDeviceData.last_seen_at) === 'critical' ? 'lastseen-offline' : '');

  return (
    <Loader loading={!edgeDevice}>
      <Container>
        <Row>
          <Column>
            <ActionBar>
              <Heading id="edge-device-details-header" title={intl.formatMessage({ id: 'edge_device_details.header' })} />
              <ActionBarButtons>
                <EditActionButton
                  id="edge-device-edit-button"
                  disabled={!accessRights.canUpdate}
                  target={`/edge_devices/${match.params.id}/edit`}
                />
                { edgeDevice && edgeDevice.add_on?.product_code === 'uplink' && accessRights.canPermit && flipper.edgeDeviceLateBinding ? (
                  <DeleteActionButton
                    id="edge-device-remove-button"
                    disabled={!accessRights.canUpdate}
                    label={intl.formatMessage({ id: 'edge_device.delete' })}
                    modalTitle={intl.formatMessage({ id: 'edge_device.delete.modal_header' })}
                    modalMessage={intl.formatMessage({ id: 'edge_device.delete.modal_message' })}
                    onConfirm={handleOnConfirmDelete}
                  />
                ) : null }
              </ActionBarButtons>
            </ActionBar>
            { edgeDevice && (
              <Details>
                <DetailsPicture>
                  {edgeDevice.edgeDeviceImageUrl
                    ? <PictureGallery additionalPictureUrls={[edgeDevice.edgeDeviceImageUrl]} />
                    : <PictureGallery placeholder={edgeDeviceIconClassName()} id="placeholder" />}
                </DetailsPicture>
                <DetailsHeaderItem id="edge-device-serialnumber" translationKey="label.serial_number" value={edgeDevice.serial_number} />
                <DetailsHeaderItem id="edge-device-name" translationKey="label.name" value={edgeDevice.name} />
                <DetailsHeaderItem id="edge-device-type" translationKey="label.type" value={edgeDevice?.type?.name} />
                <DetailsItem
                  id="edge-device-status"
                  icon={edgeDeviceStatusIconClassName(edgeDevice.status.code, edgeDevice.last_seen_at)}
                  translationKey="label.status"
                  value={edgeDevice.status.name}
                />
                <DetailsItem
                  id="edge-device-notifications"
                  icon={lcmIotIconCirculeRedGreenIcon(edgeDevice.isEdgeDeviceNotificationsOfflineStatusActive)}
                  translationKey="label.notifications"
                  contextHelperTitle={intl.formatMessage({ id: 'edge_device.user_notification.offline.hint_heading' })}
                  contextHelperChildren={intl.formatMessage({ id: 'edge_device.user_notification.offline.hint_text' })
                     + intl.formatMessage({ id: 'edge_device.user_notification.offline.hint_text2' })}
                >
                  {edgeDevice.isEdgeDeviceNotificationsOfflineStatusActive ? <FormattedMessage id="label.active" /> : <FormattedMessage id="label.inactive" />}
                </DetailsItem>
                  { isLogLevelEnabled(edgeDevice) && (
                    <DetailsItem
                      id="edge-device-log-level"
                      translationKey="label.log_level"
                      value={edgeDevice.log_level ? intl.formatMessage({ id: `enum.edge_device_log_level.${edgeDevice.log_level.toLowerCase()}` }) : null}
                    />
                  )}
                <DetailsItem id="edge-device-description" translationKey="label.description" value={edgeDevice.description} />
                <DetailsItem
                  id="edge-device-last-seen"
                  translationKey="label.last_seen"
                  icon={lastSeenStatus(edgeDevice.last_seen_at)}
                >
                  { edgeDevice.last_seen_at ? (
                    <span className={getLastSeenTextColor(edgeDevice)}>
                      <FormattedRelativeTime value={new Date(edgeDevice.last_seen_at)} />
                    </span>
                  ) : /* istanbul ignore next */ null}
                </DetailsItem>
                <DetailsItem id="edge-device-tenant" translationKey="label.tenant" value={edgeDevice.tenant} />
                <DetailsItem id="edge-device-usergroup" translationKey="label.usergroup" value={edgeDevice.usergroup} />
                <DetailsItem id="edge-device-last-applied" translationKey="label.last_applied">
                  { edgeDevice.apply_timestamp ? (
                    <FormattedRelativeTime value={new Date(edgeDevice.apply_timestamp)} />
                  ) : /* istanbul ignore next */ null}
                </DetailsItem>
                <DetailsItem id="edge-device-software-version" translationKey="label.software_version" value={edgeDevice.software_version?.version_name} />
                  {Array.isArray(edgeDevice.updateSchedules) && edgeDevice.updateSchedules.length > 0 && (
                    <More id="edge-device-update-schedules-more" label={{ openId: 'edge-device_software-updates', closeId: 'edge-device_software-updates' }}>
                      <UpdateSchedules
                        accessRights={accessRights}
                        updateSchedules={edgeDevice.updateSchedules}
                        edgeDevice={edgeDevice}
                      />
                    </More>
                  )}
                  { edgeDevice.ethernetip_supported && (
                  <DetailsItem
                    id="eip-activation-status"
                    translationKey="label.eip.activation_status"
                    icon={fieldGatewayEnableStatusIconClassName(edgeDevice.ethernet_ip_active)}
                  >
                    {edgeDevice.ethernet_ip_active ? <FormattedMessage id="label.active" /> : <FormattedMessage id="label.inactive" />}
                  </DetailsItem>
                  )}
                  {edgeDevice.api_subscription && (
                  <DetailsItem
                    id="netilion-connect-client-application-name"
                    translationKey="label.connect_client_application"
                    value={edgeDevice.client_application?.name}
                  />
                  )}
              </Details>
            )}
            { areFieldGatewaysEnabled(edgeDevice) && (
              <FieldGateways
                fieldGateways={edgeDevice.fieldGateways}
                edgeDevice={edgeDevice}
              />
            )}
            { areNetworkInterfacesEnabled(edgeDevice) && (
            <NetworkInterfaces
              networkInterfaces={edgeDevice.networkInterfaces}
              edgeDevice={edgeDevice}
            />
            )}
            { edgeDevice && edgeDevice.type.code === EdgeDeviceTypes.SGC200 && (
              <>
                <Heading title={intl.formatMessage({ id: 'edge_device_details.connectivity_settings' })} level={2} />
                <DetailsItem
                  id="data-collection-interval"
                  translationKey="label.data_collection_interval"
                  value={edgeDevice && edgeDevice.data_collection_interval ? `${edgeDevice.data_collection_interval} ${intl.formatMessage({ id: 'label.minutes' })}` : '-'}
                />
                <DetailsItem
                  id="data-send-interval"
                  translationKey="label.data_send_interval"
                  value={edgeDevice && edgeDevice.data_send_interval ? `${edgeDevice.data_send_interval} ${intl.formatMessage({ id: 'label.minutes' })}` : '-'}
                />
                { /* temporarily disabled because of LIH-6726 and LIH-6727 */ }
                {/* <DetailsItem
                  id="data-send-start-time"
                  translationKey="label.data_send_start_time"
                  className="hidden"
                  value={edgeDevice && edgeDevice.data_send_start_time ? edgeDevice.data_send_start_time : '-'}
                /> */}
                { /* temporarily disabled because of LIH-6726 and LIH-6727 */ }
                {/* <DetailsItem
                  id="data-send-environment"
                  translationKey="label.data_send_environment"
                  className="hidden"
                  value={edgeDevice && edgeDevice.data_send_environment ? edgeDevice.data_send_environment : '-'}
                /> */}
              </>
            )}
            { edgeDevice?.api_subscription && (
            <EdgeDeviceApplications
              edgeDeviceApplications={edgeDevice.edgeDeviceApplications}
              accessRights={accessRights}
            />
            ) }
          </Column>
        </Row>
      </Container>
    </Loader>
  );
}

EdgeDeviceDetails.propTypes = {
  intl: intlShape.isRequired,
  accessRights: accessRightsShape.isRequired,
  user: userShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      edgeDeviceId: PropTypes.string,
    }),
  }).isRequired,
  api: apiShape.isRequired,
  notifier: notifierShape.isRequired,
  browser: browserShape.isRequired,
  flipper: PropTypes.shape({
    edgeDeviceLateBinding: PropTypes.bool,
  }).isRequired,
};

export default withUser(withIntl(withFlipper(withSession(withApi(withNotifier(withBrowser(withAccessRights(EdgeDeviceDetails, 'EDM::EdgeDevice'))))))));
