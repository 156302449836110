import backendApiClient from './backendApiClient';
import { extractPlanCart } from '../extractors/planCartExtractor';
import { packPlanCartCheckout } from '../packmans/planCartPackman';

/* istanbul ignore next */
export async function loadSubscriptionPlanCart() {
  return extractPlanCart(await backendApiClient().get('/plan_cart', { language: global.configuration.language }));
}

/* istanbul ignore next */
export async function checkoutSubscriptionPlanCart(customerPurchaseOrder, billingAddress, shippingAddress, autoCollection, customerId) {
  const planCart = packPlanCartCheckout(customerPurchaseOrder, billingAddress, shippingAddress, autoCollection, customerId);
  return extractPlanCart(await backendApiClient().post('/plan_cart/checkout', { language: global.configuration.language, plan_cart: planCart }));
}

/* istanbul ignore next */
export async function planCartCheckout() {
  return extractPlanCart(await backendApiClient().post('/plan_cart/checkout', { language: global.configuration.language }));
}

/* istanbul ignore next */
export async function updatePlanCartParameters(updateParameters) {
  return extractPlanCart(await backendApiClient().patch('/plan_cart', updateParameters));
}

/* istanbul ignore next */
export async function upgradePlanCart() {
  return backendApiClient().patch('/plan_cart/upgrade');
}
