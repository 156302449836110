import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash-es';
import { Column, Container, Row } from '../Grid';
import ActionBar from '../ActionBar/ActionBar';
import Heading from '../Heading';
import Loader from '../Loader';
import PermissionsEdit from '../Permissions/PermissionsEdit';
import PermissionType from '../../constants/PermissionType';
import {
  Form, CancelButton, SubmitButton, TextInput, ButtonGroup,
} from '../Form';
import { intlShape } from '../../shapes';
import {
  withApi, apiShape, notifierShape, withNotifier,
} from '../../context';
import withAccessRights from '../../wrappers/withAccessRights';
import BackButton from '../BackButton';

export function SubscriptionEdit({
  intl, match, api, notifier,
}) {
  const [subscription, setSubscription] = useState();
  React.useEffect(() => {
    const loadSubscription = async () => {
      try {
        const response = await api.get(`/subscriptions/${match.params.id}`, { include: 'customer' });
        setSubscription(response);
      } catch (error) {
        notifier.showError(api.translateError(error));
      }
    };

    loadSubscription();
  }, [match]);

  const validateForm = (values) => {
    const errors = {};

    if (isEmpty(values.label)) {
      errors.label = intl.formatMessage({ id: 'validation.label.mandatory' });
    }

    if (isEmpty(values.customer_number)) {
      errors.customer_number = intl.formatMessage({ id: 'validation.label.mandatory' });
    }
    return errors;
  };

  const renderForm = (formProps) => {
    const { isSubmitting } = formProps;
    return (
      <Form {...formProps}>
        <TextInput
          {...formProps}
          id="label"
          name="label"
          label={intl.formatMessage({ id: 'label.name' })}
          required
        />
        <TextInput
          {...formProps}
          id="customer_number"
          name="customer_number"
          label={intl.formatMessage({ id: 'label.account_number' })}
          required
        />
        <ButtonGroup>
          <SubmitButton
            id="subscription-form-submit-button"
            fetching={isSubmitting}
            disabled={!formProps.dirty}
          />
          <CancelButton
            id="subscription-form-cancel-button"
            fetching={isSubmitting}
          />
        </ButtonGroup>
      </Form>
    );
  };

  const handleOnSubmit = async (values, actions) => {
    try {
      await api.patch(`/subscriptions/${subscription.id}`, { reseller_label: values.label });
      await api.patch(`/customers/${subscription.customer.id}`, { number: values.customer_number });
      notifier.showSuccess(intl.formatMessage({ id: 'subscription_edit.success_notification' }));
      actions.resetForm(values);
    } catch (error) {
      notifier.showError(api.translateError(error));
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Loader loading={!subscription}>
      <Container>
        <Row>
          <Column>
            <BackButton />
          </Column>
        </Row>
        <Row>
          <Column>
            <ActionBar>
              <Heading title={intl.formatMessage({ id: 'subscription_edit.header' })} />
            </ActionBar>
          </Column>
        </Row>
        {subscription && !isEmpty(subscription.reseller_label || subscription.customer?.number) && (
        <Row>
          <Column>
            <Formik
              id="subscription-form"
              initialValues={{
                label: subscription.reseller_label,
                customer_number: subscription.customer.number,
              }}
              onSubmit={handleOnSubmit}
              validate={validateForm}
              render={renderForm}
            />
          </Column>
        </Row>
        )}
        <Row>
          <Column>
            <PermissionsEdit
              id="subscription-edit-permissions"
              permitableType="Subscription"
              permitableId={Number(match.params.id)}
              targetOnDeleteOwnReadPermission="/subscription"
              allowedPermissions={[PermissionType.READ, PermissionType.UPDATE, PermissionType.PERMIT]}
            />
          </Column>
        </Row>
      </Container>
    </Loader>
  );
}

SubscriptionEdit.propTypes = {
  intl: intlShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }),
  api: apiShape.isRequired,
  notifier: notifierShape.isRequired,
};

export default injectIntl(withNotifier(withApi(withAccessRights(SubscriptionEdit, 'Subscription'))));
