/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import filesize from 'filesize';
import { intlShape } from '../../shapes';

import {
  isNotEmpty,
  scaleImageFile, iconForFileName, isEmpty,
} from '../../utils';
import {
  handleFormikValueChange,
} from '../../utils/formikUtils';

import AlertType from '../../constants/AlertType';
import InputAlert from '../Form/InputAlert';
import { withIntl } from '../../wrappers';
import { ActionButton } from '../ActionBar';

export function FilesUpload(props) {
  const {
    intl, whiteList, dropzoneInfoPrefix, fileSizeLimit, classNamePrefix, onDrop, multiple, buildInWhiteList,
    subscriptionStorageLimit, currentSubscriptionStorage, name, errors, values,
  } = props;
  const [rejectedList, setRejectedList] = useState([]);
  const [acceptedList, setAcceptedList] = useState([]);

  const onDropDefault = (acceptedFiles, rejectedFiles) => {
    let allRejectedFiles = isNotEmpty(rejectedFiles) ? rejectedFiles : [];
    const allAcceptedFiles = isNotEmpty(acceptedFiles) ? acceptedFiles : [];

    setRejectedList(allRejectedFiles);
    setAcceptedList(allAcceptedFiles);

    let promiseResults = [];
    let filesArray = [];
    let includedFiles = [];
    if (multiple) {
      const previouslyAcceptedFiles = values && values[props.name]?.acceptedFiles;
      includedFiles = isNotEmpty(previouslyAcceptedFiles) ? previouslyAcceptedFiles : [];
    }

    if (isNotEmpty(acceptedFiles)) {
      acceptedFiles.forEach((file) => {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
        promiseResults = promiseResults.concat(scaleImageFile(file, 1200, 0.8).then((compressedFile) => {
          filesArray = filesArray.concat(compressedFile);
        }));
      });
      Promise.all(promiseResults).then(() => {
        acceptedFiles.forEach((file, index) => {
          if (whiteList.length > 0 && !whiteList.find((suffix) => file.name.toLowerCase().endsWith(suffix))) {
            file.uploadError = 'incompatible_type_error';
            allRejectedFiles = allRejectedFiles.concat(file);
          } else if (isNotEmpty(filesArray) && fileSizeLimit && filesArray[index].size > Number(fileSizeLimit)) {
            file.uploadError = 'size_limit_exceeded';
            allRejectedFiles = allRejectedFiles.concat(file);
          } else if (isNotEmpty(filesArray) && subscriptionStorageLimit
            && currentSubscriptionStorage
            && filesArray[index].size > (Number(subscriptionStorageLimit) - Number(currentSubscriptionStorage))) {
            file.uploadError = 'subscription_storage_limit_exceeded';
            allRejectedFiles = allRejectedFiles.concat(file);
          } else {
            includedFiles = includedFiles.concat(file);
          }
        });
      }).then(() => {
        setAcceptedList(includedFiles);
        setRejectedList(allRejectedFiles);
        handleFormikValueChange(props, { acceptedFiles: includedFiles, rejectedFiles: allRejectedFiles });
      });
    }
  };

  const {
    getRootProps, getInputProps, isDragActive, acceptedFiles,
  } = useDropzone({ accept: buildInWhiteList, onDrop: (onDrop || onDropDefault), multiple });

  const onDeleteDefault = () => {
    acceptedFiles.splice(-1, 1);
    acceptedList.splice(-1, 1);
    setAcceptedList(acceptedList);
    setRejectedList([]);
    handleFormikValueChange(props, { acceptedFiles: acceptedList, rejectedFiles: rejectedList });
  };
  const rejectedFilesRendered = rejectedList.map((file) => {
    let whiteListReadable = `( ${whiteList.join(', ')} )`;
    if (isEmpty(whiteList)) {
      whiteListReadable = buildInWhiteList;
    }

    if (isEmpty(file.name) && file.file) {
      // only needed when the file is rejected directly from the dropzone (e.g. because of type)
      // the name variant is only needed for cypress headless browser
      /* istanbul ignore next */
      file.name = isNotEmpty(file.file.path) ? file.file.path : file.file.name;
    }

    switch (file.uploadError) {
      case 'size_limit_exceeded':
        file.uploadErrorMessage = intl.formatMessage({ id: 'dropzone.size_error' }, { limit: filesize(fileSizeLimit) });
        break;
      case 'subscription_storage_limit_exceeded':
        file.uploadErrorMessage = intl.formatMessage({ id: 'dropzone.subscription_storage_limit_exceeded' });
        break;
      case 'incompatible_type_error':
        file.uploadErrorMessage = intl.formatMessage({ id: 'dropzone.incompatible_type_error' }, { whitelist: whiteListReadable });
        break;
      default:
        file.uploadErrorMessage = intl.formatMessage({ id: 'dropzone.incompatible_type_error' }, { whitelist: whiteListReadable });
    }
    return (
      <li id="rejected-file" key={file.name}>
        <strong>{file.name}</strong>
        :
        {' '}
        {file.uploadErrorMessage}
      </li>
    );
  });

  let rejectionError = null;
  if (errors && errors[name]) {
    rejectionError = (
      <div id="rejection-input-error" className="files-rejection-full-width">
        <InputAlert message={errors[name]} type={AlertType.ERROR} />
      </div>
    );
  }
  if (rejectedList && rejectedList.length > 0) {
    if (multiple) {
      rejectionError = (
        <div id="rejection-input-alert" className="files-rejection-full-width">
          <InputAlert
            message={intl.formatMessage({ id: 'dropzone.rejection' })}
            type={AlertType.ERROR}
          >
            <ul>{rejectedFilesRendered}</ul>
          </InputAlert>
        </div>
      );
    } else if (rejectedList.length > 1) {
      // this happens if the user drop multiple files with multiple false dropzone
      rejectionError = (
        <div id="rejection-input-alert" className="files-rejection-full-width">
          <ul><li>{intl.formatMessage({ id: 'dropzone.mutiple_drops_forbidden' })}</li></ul>
        </div>
      );
    } else {
      rejectionError = (
        <div id="rejection-input-alert" className="files-rejection-full-width">
          <ul>{rejectedFilesRendered}</ul>
        </div>
      );
    }
  }

  const style = useMemo(() => ({
    ...{ className: `${classNamePrefix}-upload` },
    ...(isDragActive ? { className: `${classNamePrefix}-upload-active` } : {}),
  }), [
    isDragActive,
  ]);

  const dropzone = (!multiple && acceptedList.length === 1) ? (
    <div id="single-file-selected" className="edit-files">
      <div className="file-delete">
        <ActionButton
          id="delete-file"
          icon="icon-eh-delete"
          defaultLabel={intl.formatMessage({ id: 'button.delete' })}
          onClick={onDeleteDefault}
        />
      </div>
      <div className="files-dropzone">
        <div className="file-selected">
          <div className={`icon ${iconForFileName(acceptedList[0].name)}`} />
          <p>{acceptedList[0].name}</p>
        </div>
      </div>
      {errors ? rejectionError : null}
    </div>
  ) : (
    <div>
      <section className={`${classNamePrefix}-dropzone`}>
        <div id={`${classNamePrefix}-dropzone-root`} {...getRootProps({ ...style })}>
          <input id={`${classNamePrefix}-dropzone-input`} data-testid={`${classNamePrefix}-dropzone-input`} {...getInputProps()} />
          <div className="icon lcm-iot-icon-upload" />
          <p id="dropzone-info">{intl.formatMessage({ id: `${dropzoneInfoPrefix}.dropzone_info` })}</p>
        </div>
      </section>
      {rejectionError}
    </div>
  );
  return (dropzone);
}

FilesUpload.defaultProps = {
  classNamePrefix: 'files',
  multiple: true,
  dropzoneInfoPrefix: 'support.ticket.create',
  whiteList: [],
};

FilesUpload.propTypes = {
  intl: intlShape.isRequired,
  dropzoneInfoPrefix: PropTypes.string,
  classNamePrefix: PropTypes.string,
  multiple: PropTypes.bool,
  fileSizeLimit: PropTypes.number,
  subscriptionStorageLimit: PropTypes.number,
  currentSubscriptionStorage: PropTypes.number,
  onDrop: PropTypes.func,

  // needed when an error has to be forwarded from parent (such as presence validations)
  name: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object,

  // buildInWhiteList can be used if you have your own drop
  // function and you want to use the built in functionality from dropzone
  buildInWhiteList: PropTypes.string,
  // whiteList can be used if you want to use the default onDrop function
  whiteList: PropTypes.arrayOf(PropTypes.string),
};

export default withIntl(FilesUpload);
