import React from 'react';
import PropTypes from 'prop-types';

const renderTitle = (title, level) => {
  switch (level) {
    case 1:
      return (<h1>{title}</h1>);
    case 2:
      return (<h2>{title}</h2>);
    case 3:
      return (<h3>{title}</h3>);
    case 4:
      return (<h4>{title}</h4>);
    default:
      return (<h5>{title}</h5>);
  }
};

function Heading({
  title, level, children, id, className,
}) {
  return (
    <div id={id} className={`heading ${className ?? ''}`.trim()}>
      { renderTitle(title, level) }
      { children }
    </div>
  );
}

Heading.propTypes = {
  id: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  level: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
};

Heading.defaultProps = {
  id: undefined,
  title: undefined,
  children: undefined,
  level: 1,
};

export default Heading;
