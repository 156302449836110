import {
  useQuery, useMutation, useQueryClient, useInfiniteQuery, useQueries,
} from '@tanstack/react-query';

/**
 * Custom hook for querying the Netilion API. It uses \@tanstack/react-query under the hood.
 * @returns The API object.
 */
export const useApiWithClient = (client, scope) => {
  const queryClient = useQueryClient();

  const createMutation = (method) => ({
    /**
     * @param url - The URL to send the request to.
     * @param {import('@tanstack/react-query').UseMutationOptions} options - Additional options for the mutation.
     * @returns The result of the mutation.
     */
    useMutation: (mutationUrl, options = {}) => useMutation({
      mutationFn: (variables) => {
        const url = variables?.mutationUrl ?? mutationUrl;
        const mutationBody = variables?.mutationBody ?? variables;
        return client[method](url, mutationBody);
      },
      onSettled: (_, __, variables) => {
        const predicate = (query) => query.queryKey[0] === (variables?.mutationUrl ?? mutationUrl)
            && query.queryKey[2]?.scope === scope;
        if (method === 'delete') {
          return queryClient.removeQueries({
            predicate,
          });
        }
        return queryClient.invalidateQueries({
          predicate,
        });
      },
      ...options,
    }),
  });

  const createQueryKey = (url, params) => [url, params ?? {}, { scope }];

  const createQuery = (method) => ({
    /**
     * @param url - The URL to send the request to.
     * @param params - URL query params as object.
     * @param {import('@tanstack/react-query').UseQueryOptions} options - Additional options for the query.
     * @returns The result of the query.
     */
    useQuery: (url, params = undefined, options = {}) => useQuery({
      queryKey: createQueryKey(url, params),
      queryFn: ({ signal }) => client[method](url, params, signal),
      ...options,
    }),
  });

  const createInfiniteQuery = (method) => ({
    /**
     * @param url - The URL to send the request to.
     * @param params - URL query params as object.
     * @param {import('@tanstack/react-query').UseInfiniteQueryOptions} options - Additional options for the query.
     * @returns The result of the query.
     */
    useInfiniteQuery: (url, params = undefined, options = {}) => useInfiniteQuery({
      queryKey: createQueryKey(url, params),
      queryFn: ({ signal, pageParam = 1 }) => client[method](url, { page: pageParam, ...params }, signal),
      getNextPageParam: (lastPage) => (lastPage.pagination.next ? lastPage.pagination.page + 1 : undefined),
      ...options,
    }),
  });

  const createQueries = (method) => ({
    /**
     * Custom hook for making multiple queries at once.
     *
     * @param {Array} queries - An array of query objects. Each query object should have the following properties:
     * @param {string} queries[].url - The URL to send the request to.
     * @param {Object} [queries[].params] - URL query params as an object.
     * @param {import('@tanstack/react-query').UseQueryOptions} [queries[].options] - Additional options for the query.
     *
     * @returns {Array} An array of the results of the queries.
     *
     * @example
     * const results = useQueries([
     *   { url: '/assets', params: { include: 'status' }, options: { enabled: true } },
     *   { url: '/assets', params: { include: 'specifications' }, options: { enabled: false } },
     * ]);
     */
    useQueries: (queries) => useQueries({
      queries: queries.map((query) => ({
        queryKey: createQueryKey(query.url, query.params),
        queryFn: ({ signal }) => client[method](query.url, query.params, signal),
        ...query.options,
      })),
    }),
  });

  const api = {
    get: { ...createQuery('get'), ...createInfiniteQuery('get'), ...createQueries('get') },
    getAll: createQuery('getAll'),
    head: { ...createQuery('head'), ...createQueries('head') },
    post: createMutation('post'),
    patch: createMutation('patch'),
    delete: createMutation('delete'),
    upload: createMutation('upload'),
  };

  return api;
};
