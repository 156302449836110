import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Formik } from 'formik';
import { extractInstrumentations } from '../../extractors';
import { NotFoundError } from '../../api';
import { SubmitButton, ObjectsTypeahead } from '../Form';
import { ObjectsType } from '../../constants';
import { accessRightsShape, intlShape } from '../../shapes';
import { sortBy } from '../../utils';
import {
  withApi, apiShape, withNotifier, notifierShape, withRules,
} from '../../context';
import List from '../List/List';
import InstrumentationItem from '../Instrumentations/InstrumentationItem';
import Hint from '../Hint';

export function InstrumentationsAssignList(props) {
  const {
    intl, api, notifier, accessRights, object,
  } = props;

  const objectTypePlural = `${object?.itemType}s`;
  const objectId = object?.id;

  const [instrumentations, setInstrumentations] = React.useState();
  const parentNode = objectTypePlural.includes('node') ? { id: parseInt(objectId, 10) } : null;
  const parentAsset = objectTypePlural.includes('asset') ? { id: parseInt(objectId, 10) } : null;

  React.useEffect(() => {
    const loadData = async () => {
      try {
        const responseInstrumentations = await api.get(`/${objectTypePlural}/${objectId}/instrumentations`, {
          include: 'specifications,status,worst_asset_status',
        });
        const loadedInstrumentations = sortBy(extractInstrumentations(responseInstrumentations), 'tag');
        setInstrumentations(loadedInstrumentations);
      } catch (error) {
        if (!(error instanceof NotFoundError)) {
          notifier.showError(api.translateError(error));
        }
      }
    };
    if (object) {
      loadData();
    }
  }, [objectId]);

  const handleOnInstrumentationRemoved = (instrumentation) => {
    setInstrumentations(instrumentations.filter((a) => a.id !== instrumentation.id));
  };

  const assignInstrumentation = async (values, { resetForm }) => {
    const payload = { instrumentations: [{ id: values.instrumentation.id }] };
    try {
      await api.post(`/${objectTypePlural}/${objectId}/instrumentations`, payload);
      setInstrumentations(sortBy(instrumentations.concat([{ ...values.instrumentation }]), 'tag'));
      notifier.showSuccess(intl.formatMessage({ id: 'instrumentation.actions.assign.notification' }));
    } catch (error) {
      if (!(error instanceof NotFoundError)) {
        notifier.showError(api.translateError(error));
      }
    }
    resetForm({});
  };

  const renderInstrumentationAssignForm = (formProps) => {
    const { handleSubmit, isSubmitting, values } = formProps;
    return (
      <form onSubmit={handleSubmit} noValidate>
        <ObjectsTypeahead
          {...formProps}
          id="instrumentation-typeahead"
          name="instrumentation"
          label={intl.formatMessage({ id: 'instrumentation.actions.assign' })}
          placeholder={intl.formatMessage({ id: 'label.tag' })}
          objectsToIgnore={instrumentations}
          objectsType={ObjectsType.Instrumentations}
        />
        <div className="btn-group">
          <SubmitButton id="assign-instrumentation-button" disabled={!values.instrumentation} fetching={isSubmitting} text={intl.formatMessage({ id: 'button.assign' })} />
        </div>
      </form>
    );
  };

  return objectId && instrumentations
    ? (
      <div id="instrumentation-list">
        <h2>
          <FormattedMessage id="instrumentations.header" />
        </h2>
        {accessRights.canPermit ? <Formik id="assign-instrumentation-formik" onSubmit={assignInstrumentation} render={renderInstrumentationAssignForm} /> : null}
        { instrumentations.length > 0
          ? (
            <List id="instrumentations_assign_list">
              {instrumentations.map((instrumentation) => (
                <InstrumentationItem
                  key={instrumentation.id}
                  instrumentation={instrumentation}
                  parentNode={parentNode}
                  parentAsset={parentAsset}
                  onInstrumentationRemoved={handleOnInstrumentationRemoved}
                  showItemMenu
                  parentAccessRights={accessRights}
                />
              ))}
            </List>
          ) : (
            <Hint
              id="hint-no-instrumentations-assigned"
              details={intl.formatMessage({ id: 'instrumentation_list.no_instrumentations_assign' })}
            />
          )}
      </div>
    ) : null;
}

InstrumentationsAssignList.propTypes = {
  api: apiShape.isRequired,
  accessRights: accessRightsShape,
  intl: intlShape.isRequired,
  object: PropTypes.shape({ id: PropTypes.number, itemType: PropTypes.oneOf(['asset', 'node']) }), // .isRequired, throws an stupid error during loading..
  notifier: notifierShape.isRequired,
};
export default injectIntl(withNotifier(withApi(withRules(InstrumentationsAssignList))));
