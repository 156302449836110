import React from 'react';
import { useSearch } from '../../hooks/useSearch';
import CountBadge from '../CountBadge';

export function AllObjectsBadge() {
  const search = useSearch();
  const resultCount = search.filters?.length === 0 ? 0 : Number(search.totalCount);

  if (search.results !== undefined || search.isSearching) {
    return <CountBadge isLoading={search.isSearching} count={resultCount} maximum={10000} idPrefix="search-results-" />;
  }
  return null;
}

export default AllObjectsBadge;
