import lcmApiClient from './lcmApiClient';

import { extractNode, extractNodes } from '../extractors/nodesExtractor';
import { extractNodeTypes } from '../extractors/nodeTypesExtractor';

/* istanbul ignore next */
export async function findNodeTypes(filter) {
  const response = await lcmApiClient().getAll('/node/types', filter);
  return extractNodeTypes(response, true);
}

export async function findAllAvailableNodeTypes(nodeId, filter) {
  const responseNodeTypes = await lcmApiClient().getAll('/node/types', filter);
  const responseTypeOptions = await lcmApiClient().getAll(`/nodes/${nodeId}/type-options`, filter);
  responseNodeTypes.node_types = responseNodeTypes.node_types.concat(responseTypeOptions.node_types).filter((nodeType, index, self) => self.findIndex((t) => t.id === nodeType.id) === index);
  return extractNodeTypes(responseNodeTypes, true);
}

/* istanbul ignore next */
export async function createNode(node, tenantId, parentId) {
  const param = {
    name: node.name,
    description: node.description,
    type: { id: node.type.id },
  };
  if (parentId) {
    param.parent = { id: parentId };
  }
  if (tenantId) {
    param.tenant = { id: tenantId };
  }
  const response = await lcmApiClient().post('/nodes', param);
  return response.id;
}

export async function updateNode(nodeId, values) {
  await lcmApiClient().patch(`/nodes/${nodeId}`, values);
}

/* istanbul ignore next */
export async function loadNextNodes(url, filter = {}) {
  const response = await lcmApiClient().get(url, filter);
  return {
    nodes: extractNodes(response),
    totalCount: response.pagination.total_count,
    nextPageUrl: response.pagination.next,
  };
}

// @deprecated
export async function loadNodes(filter = {}) {
  const response = await lcmApiClient(false).get('/nodes', filter);
  return {
    nodes: extractNodes(response),
    nextPageUrl: response?.pagination?.next,
  };
}

// @deprecated
export async function loadNode(id, parameters) {
  const response = await lcmApiClient().get(`/nodes/${id}`, parameters);
  return extractNode(response);
}

/* istanbul ignore next */
export async function createNodeType(nodeType, tenantId) {
  const param = {
    name: nodeType.name,
  };
  if (tenantId) {
    param.tenant = { id: tenantId };
  }
  const response = await lcmApiClient().post('/node/types', param);
  return response.id;
}
