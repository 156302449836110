import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslateError } from '../hooks';

const ApiContext = React.createContext();

const apiShape = PropTypes.shape({
  get: PropTypes.func,
  getAll: PropTypes.func,
  post: PropTypes.func,
  patch: PropTypes.func,
  delete: PropTypes.func,
  head: PropTypes.func,
  upload: PropTypes.func,
  translateError: PropTypes.func,
});

function InternalApiContextProvider({
  lcmApiClient, children, testApi,
}) {
  const translateError = useTranslateError();

  const context = useMemo(() => {
    if (testApi) {
      const api = testApi;
      return { api };
    }
    return {
      api: {
        get: (url, parameters, cache = true, signal = undefined) => lcmApiClient(cache, true).get(url, parameters, signal),
        getAll: (url, parameters, cache, signal = undefined) => lcmApiClient(cache, true).getAll(url, parameters, signal),
        post: (url, parametersOrObject, objectOrNull = null) => lcmApiClient(true, true).post(url, parametersOrObject, objectOrNull),
        patch: (url, parametersOrObject, objectOrNull = null) => lcmApiClient(true, true).patch(url, parametersOrObject, objectOrNull),
        delete: (url, parametersOrObject, objectOrNull = null) => lcmApiClient(true, true).delete(url, parametersOrObject, objectOrNull),
        head: (url, parameters) => lcmApiClient(true, true).head(url, parameters),
        upload: (url, parameters) => lcmApiClient(true, true).upload(url, parameters),
        translateError,
      },
    };
  }, [translateError]);
  return (
    <ApiContext.Provider value={context}>
      {children}
    </ApiContext.Provider>
  );
}

InternalApiContextProvider.propTypes = {
  lcmApiClient: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  testApi: PropTypes.shape({}),
};

InternalApiContextProvider.defaultProps = {
  testApi: undefined,
};

const ApiContextProvider = InternalApiContextProvider;

const withApi = (ChildComponent) => {
  function ConnectedComponent(props) {
    return (
      <ApiContext.Consumer>
        {(context = {}) => <ChildComponent {...props} api={context.api} />}
      </ApiContext.Consumer>
    );
  }
  ConnectedComponent.displayName = ChildComponent.displayName || ChildComponent.name;
  return ConnectedComponent;
};

export {
  ApiContextProvider,
  ApiContext,
  apiShape,
  withApi,
};
