/* eslint-disable no-use-before-define */
import { lcmApiClient } from 'lcm-iot-commons';
import { extractEdgeDevices } from '../extractors/edgeDevicesExtractor';

/* istanbul ignore next */
export async function loadEdgeDevices(parameters) {
  const response = await lcmApiClient().get('/edm/edge_devices', { ...parameters, include: 'status, type, add_on' });
  return {
    edgeDevices: extractEdgeDevices(response),
    totalCount: response.pagination.total_count,
    nextPageUrl: response.pagination.next,
  };
}

/* istanbul ignore next */
export async function loadNextEdgeDevices(nextPageUrl) {
  const response = await lcmApiClient().get(nextPageUrl);
  return {
    edgeDevices: extractEdgeDevices(response),
    totalCount: response.pagination.total_count,
    nextPageUrl: response.pagination.next,
  };
}

/* istanbul ignore next */
export async function loadEdgeDeviceApplications(edgeDeviceTypeId, parameters) {
  const edgeDeviceApplicationTypeId = (await loadPublicEdgeDeviceApplicationType()).id;
  const response = await lcmApiClient(false).getAll(`/edm/edge_device/application/types/${edgeDeviceApplicationTypeId}/applications`, {
    edge_device_type_id: edgeDeviceTypeId,
    ...parameters,
  });
  return {
    edgeDeviceApplications: response.edge_device_applications,
  };
}

/* istanbul ignore next */
export async function loadPublicEdgeDeviceApplicationType(parameters) {
  const response = await lcmApiClient(false).getAll('/edm/edge_device/application/types', {
    code: 'public',
    ...parameters,
  });
  return response.edge_device_application_types[0];
}

/* istanbul ignore next */
export async function loadEnabledEdgeDeviceApplications(edgeDeviceId, parameters) {
  const response = await lcmApiClient(false).getAll(`/edm/edge_devices/${edgeDeviceId}/applications`, parameters);
  return {
    edgeDeviceApplications: response.edge_device_applications,
  };
}

/* istanbul ignore next */
export async function assignEdgeDeviceApplications(edgeDeviceId, body) {
  return lcmApiClient().post(`/edm/edge_devices/${edgeDeviceId}/applications`, body);
}

/* istanbul ignore next */
export async function unassignEdgeDeviceApplications(edgeDeviceId, body) {
  return lcmApiClient().delete(`/edm/edge_devices/${edgeDeviceId}/applications`, body);
}

/* istanbul ignore next */
export async function removeEdgeDevice(edgeDeviceId) {
  return lcmApiClient().patch(`/edm/edge_devices/${edgeDeviceId}/unlink`);
}

/* istanbul ignore next */
export async function loadEdgeDeviceAddOn(url) {
  return lcmApiClient(false).get(url);
}

export async function loadEdgeDeviceUplinkAddons() {
  return lcmApiClient().get('/add_ons?scope=USABLE&add_on_type=connectivity&product_code=uplink&include=addonable_name');
}
