import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { withIntl } from '../wrappers';
import { intlShape } from '../shapes';

import Row from './Grid/Row';
import Column from './Grid/Column';
import { isString } from '../utils';

export function ConfirmationModal({
  show,
  titleText,
  messageText,
  buttonConfirmText,
  buttonAltConfirmText,
  buttonCancelText,
  onConfirm,
  onClose,
  onAltConfirm,
  backdrop,
  intl,
  image,
  showConfirm,
  showCancel,
  showAlternative,
  secondConfirmText,
  secondConfirmLabel,
  children,
}) {
  const [value, setValue] = useState('');
  let secondConfirmStepElement;
  let disableConfirm = false;

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const confirmText = isString(buttonConfirmText) ? buttonConfirmText : intl.formatMessage({ id: 'button.confirm' });
  const alternativeText = isString(buttonAltConfirmText) ? buttonAltConfirmText : intl.formatMessage({ id: 'button.alternative' });
  const cancelText = isString(buttonCancelText) ? buttonCancelText : intl.formatMessage({ id: 'button.cancel' });

  if (secondConfirmText && secondConfirmLabel) {
    disableConfirm = (value !== secondConfirmText);
    secondConfirmStepElement = (
      <Row>
        <Column>
          <form className="input-group margin-top" noValidate>
            <p>{secondConfirmLabel}</p>
            <input
              id="confirmation-second-step"
              data-testid="confirmation-second-step"
              className="space-before"
              type="text"
              value={value}
              onChange={handleChange}
            />
          </form>
        </Column>
      </Row>
    );
  }

  const modalHeader = (
    <Modal.Header data-testid="modal-header" closeButton>
      <Modal.Title>
        {titleText}
      </Modal.Title>
    </Modal.Header>
  );

  const modalBody = (
    <Modal.Body data-testid="modal-body">
      {image ? (
        <Row>
          <Column>{image && <img data-testid="modal-body-image" alt="device" src={image} />}</Column>
        </Row>
      ) : null}
      <Row>
        <Column>
          {messageText}
        </Column>
      </Row>
      {secondConfirmStepElement}
      {children}
    </Modal.Body>
  );

  const renderConfirmButton = showConfirm ? (
    <div className="confirmation-button" data-three-buttons={showAlternative.toString()}>
      <button
        id="button-confirm"
        data-testid="test-button-confirm"
        type="button"
        disabled={disableConfirm}
        className="btn btn-primary"
        onClick={onConfirm}
      >
        {confirmText}
      </button>
    </div>
  ) : null;
  const renderAltConfirmButton = showAlternative ? (
    <div className="confirmation-button" data-three-buttons={showAlternative.toString()}>
      <button
        id="button-alternative"
        data-testid="test-button-alternative"
        type="button"
        className="btn"
        onClick={onAltConfirm}
      >
        {alternativeText}
      </button>
    </div>
  ) : null;

  const renderCloseButton = showCancel ? (
    <div className="confirmation-button" data-three-buttons={showAlternative.toString()}>
      <button
        id="button-cancel"
        data-testid="test-button-cancel"
        type="button"
        className="btn btn-default"
        onClick={onClose}
      >
        {cancelText}
      </button>
    </div>
  ) : null;

  const modalFooter = (
    <Modal.Footer data-testid="modal-footer">
      <Row>
        <Column>
          <div className="modal-btn-group">
            {renderConfirmButton}
            {renderAltConfirmButton}
            {renderCloseButton}
          </div>
        </Column>
      </Row>
    </Modal.Footer>
  );

  return (
    <Modal show={show} onHide={onClose} restoreFocus={false} backdrop={backdrop}>
      {modalHeader}
      {modalBody}
      {modalFooter}
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  show: PropTypes.bool,
  titleText: PropTypes.string,
  buttonConfirmText: PropTypes.string,
  buttonAltConfirmText: PropTypes.string,
  buttonCancelText: PropTypes.string,
  messageText: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  image: PropTypes.string,
  onConfirm: PropTypes.func,
  onAltConfirm: PropTypes.func,
  onClose: PropTypes.func,
  backdrop: PropTypes.string,
  intl: intlShape.isRequired,
  showConfirm: PropTypes.bool,
  showCancel: PropTypes.bool,
  showAlternative: PropTypes.bool,
  secondConfirmText: PropTypes.string,
  secondConfirmLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  children: PropTypes.node,
};

ConfirmationModal.defaultProps = {
  show: false,
  titleText: undefined,
  buttonConfirmText: undefined,
  buttonAltConfirmText: undefined,
  buttonCancelText: undefined,
  messageText: undefined,
  image: undefined,
  showConfirm: true,
  showCancel: true,
  showAlternative: false,
  secondConfirmText: undefined,
  secondConfirmLabel: undefined,
  children: null,
};

export default withIntl(ConfirmationModal);
