import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom-v5-compat';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import ConnectedAssetSetupClue from '../Assets/ConnectedAssetSetupClue';
import SubscriptionLimitClue from '../Subscriptions/SubscriptionLimitClue';
import {
  extractNode,
  extractNodes,
  extractInstrumentations,
  extractAssets,
} from '../../extractors';

import Loader from '../Loader';
import Search from '../Search/Search';

import { isNotEmpty, url } from '../../utils';

import AllObjectsHeader from './AllObjectsHeader';
import AllObjectsList from './AllObjectsList';
import AllObjectsNoAssetsFoundClue from './AllObjectsNoAssetsFoundClue';

import { Container, Row, Column } from '../Grid';
import {
  rulesShape,
  subscriptionShape,
  useNotifier,
  withRules,
  withSubscription,
} from '../../context';
import { withFlipper } from '../../wrappers';
import NestedNodeBreadcrumb from '../Breadcrumb/NestedNodeBreadcrumb';
import { useSearch } from '../../hooks/useSearch';
import { useAccessRightsQuery, useApi, useTranslateError } from '../../hooks';
import { ConflictError } from '../../api';
import { ListSkeleton } from '../List';

export function AllObjects({
  additionalCreateMenuItems,
  rules,
  subscription,
  flipper,
}) {
  const api = useApi();
  const intl = useIntl();
  const notifier = useNotifier();
  const navigate = useNavigate();
  const { id: nodeId } = useParams();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const search = useSearch();
  const translateError = useTranslateError();

  const isAllObjects = !(search.results || search.isSearching);

  const getLoad = (key, value) => {
    if (!isAllObjects) return false;
    if (!flipper.allobjectFilterActive) return true;
    if (searchParams.has(key)) {
      if (key === 'filter') return search.filters.includes(value);
      /* istanbul ignore else */ if (key === 'option') return search.options.includes(value);
    }
    return true;
  };

  const shouldLoadNodes = getLoad('filter', 'nodes');
  const shouldLoadTags = getLoad('filter', 'instrumentations');
  const shouldLoadAssets = getLoad('filter', 'assets');
  const showSpecifications = getLoad('option', 'specifications');

  const { data: accessRights, isFetching: isFetchingAccessRights, isInitialLoading: isInitialLoadingAccessRights } = useAccessRightsQuery('Node', { enabled: !!nodeId });

  const {
    data: node, isFetching: isFetchingNode, isInitialLoading: isInitialLoadingNode,
  } = api.get.useQuery(
    `/nodes/${nodeId}`,
    { include: 'parent,type,type.parent' },
    {
      enabled: subscription?.subscriptionLoaded && !!nodeId && !!accessRights,
      select: (nodeData) => (extractNode(nodeData)),
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const enabled = subscription?.subscriptionLoaded && (nodeId ? !!node : true);

  const nodesInclude = [rules.application().get('nodesIncludes'), 'worst_asset_status'];
  if (showSpecifications) nodesInclude.push('specifications');
  const {
    data: nodes, isFetching: isFetchingNodes, isInitialLoading: isInitLoadingNodes, hasNextPage: hasNextPageNodes, fetchNextPage: fetchNextPageNodes,
  } = api.get.useInfiniteQuery(
    '/nodes',
    {
      parent_id: node?.id || 'null', include: nodesInclude.join(','), order_by: 'name', per_page: 25, include_total_count: false,
    },
    {
      enabled: enabled && shouldLoadNodes,
      select: (nodesData) => (extractNodes({
        nodes: nodesData?.pages?.map((page) => page.nodes).flat(),
        pagination: nodesData?.pages[nodesData.pages.length - 1].pagination,
      })),
      refetchOnWindowFocus: false,
    },
  );

  const tagsInclude = ['status', 'type', 'worst_asset_status'];
  if (showSpecifications) tagsInclude.push('specifications');
  const {
    data: tags, isFetching: isFetchingTags, isInitialLoading: isInitLoadingTags, hasNextPage: hasNextPageTags, fetchNextPage: fetchNextPageTags,
  } = api.get.useInfiniteQuery(
    '/instrumentations',
    {
      include: tagsInclude.join(','), system_id: 'null', node_id: node?.id || 'null', order_by: 'tag', per_page: 25, include_total_count: false,
    },
    {
      enabled: enabled && shouldLoadTags && (shouldLoadNodes ? (!isFetchingNodes && !hasNextPageNodes) : true),
      select: (tagsData) => (extractInstrumentations({
        instrumentations: tagsData?.pages?.map((page) => page.instrumentations).flat(),
        pagination: tagsData?.pages[tagsData.pages.length - 1].pagination,
      })),
      refetchOnWindowFocus: false,
    },
  );

  const assetsInclude = ['product.manufacturer', 'product.tenant', 'product.pictures', 'status'];
  if (showSpecifications) assetsInclude.push('specifications');
  const {
    data: assets, isFetching: isFetchingAssets, isInitialLoading: isInitLoadingAssets, hasNextPage: hasNextPageAssets, fetchNextPage: fetchNextPageAssets,
  } = api.get.useInfiniteQuery(
    '/assets',
    {
      include: assetsInclude.join(','),
      node_id: node?.id || 'null',
      instrumentation_id: 'null',
      parent_id: 'null',
      order_by: 'serial_number',
      per_page: 100,
      include_total_count: false,
    },
    {
      enabled: enabled && shouldLoadAssets && (shouldLoadNodes ? (!isFetchingNodes && !hasNextPageNodes) : true) && (shouldLoadTags ? (!isFetchingTags && !hasNextPageTags) : true),
      select: (assetsData) => (extractAssets({
        assets: assetsData?.pages?.map((page) => page.assets).flat(),
        pagination: assetsData?.pages[assetsData.pages.length - 1].pagination,
      })),
      refetchOnWindowFocus: false,
    },
  );

  const { mutate: deleteNode } = api.delete.useMutation(`/nodes/${node?.id}`, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/nodes'] });
      notifier.showSuccess(intl.formatMessage({ id: 'node.actions.delete.notification' }));
      if (node.parent) navigate(url(`/nodes/${node?.parent.id}`));
      else navigate(url('/nodes'));
    },
    onError: (error) => {
      if (error instanceof ConflictError) {
        notifier.showError(intl.formatMessage({ id: 'api.error.node.assigned_restriction' }));
      } else notifier.showError(translateError(error));
    },
  });

  const navigateToFirstResult = search.results?.length === 1 && search.followFirstResult;

  React.useEffect(() => {
    if (navigateToFirstResult) {
      const { href } = search.results[0];
      const parts = href.split(/endress\.com\/v(\d)\/(assets|instrumentations|nodes)\//).slice(-2);
      if (parts.length === 2) {
        navigate(url(`/${parts[0]}/${parts[1]}`), { replace: true });
      }
    }
  }, [navigateToFirstResult]);

  React.useEffect(() => {
    if (!shouldLoadNodes) queryClient.cancelQueries({ queryKey: ['/nodes'] });
    if (!shouldLoadTags) queryClient.cancelQueries({ queryKey: ['/instrumentations'] });
    if (!shouldLoadAssets) queryClient.cancelQueries({ queryKey: ['/assets'] });
  }, [search.filters, search.options]);

  const handleOnItemRemoved = (item) => {
    if (item.itemType === 'node') queryClient.invalidateQueries({ queryKey: ['/nodes'] });
    else if (item.itemType === 'instrumentation') queryClient.invalidateQueries({ queryKey: ['/instrumentations'] });
    else queryClient.invalidateQueries({ queryKey: ['/assets'] });
  };

  const handleOnConfirmDelete = async () => deleteNode();

  const handleOnNodeSelect = (nextNode) => {
    if (!nextNode) navigate(url('/nodes'));
    else navigate(url(`/nodes/${nextNode.id}`));
  };

  const fetchMoreData = () => {
    if (shouldLoadNodes && hasNextPageNodes) fetchNextPageNodes();
    else if (shouldLoadTags && !hasNextPageNodes && hasNextPageTags) fetchNextPageTags();
    else if (shouldLoadAssets && !hasNextPageNodes && !hasNextPageTags && hasNextPageAssets) fetchNextPageAssets();
  };

  const isPreLoading = !subscription?.subscriptionLoaded || isFetchingAccessRights || isFetchingNode;
  const items = [];
  if (shouldLoadNodes && !isPreLoading) items.push(...(nodes || []));
  if (shouldLoadTags && !isPreLoading) items.push(...(tags || []));
  if (shouldLoadAssets && !isPreLoading) items.push(...(assets || []));

  const hasMore = !!hasNextPageNodes || !!hasNextPageTags || !!hasNextPageAssets;

  const isInitialLoading = isInitLoadingNodes || isInitLoadingTags || isInitLoadingAssets;
  const isFetching = isFetchingAccessRights || isFetchingNode || isFetchingNodes || isFetchingTags || isFetchingAssets;

  const showSkeleton = isAllObjects && items.length === 0 && (isPreLoading || isInitialLoading || !!(nodeId && !accessRights));
  const showLoader = !subscription?.subscriptionLoaded || isFetching || !!(nodeId && !accessRights);

  // if coming from ID the user session does not have the subscriptionAssetQuota, this case is excluded and noAssetFound is shown.
  const showNoAssetsFound = (!showLoader && items.length === 0)
    && (!subscription?.asset_quota || subscription?.asset_quota < 0 || (subscription?.usage?.asset_count < subscription?.asset_quota))
    && (!node || accessRights?.canUpdate)
    && (shouldLoadAssets && shouldLoadTags && shouldLoadNodes);

  const showNothingFound = isAllObjects && subscription?.subscriptionLoaded && !isFetching && !isFetchingAccessRights && !isFetchingNode && !isInitialLoading && items.length === 0
  && !showNoAssetsFound;
  const showNothingFoundSearch = !isAllObjects && !search.isSearching && search.filters.length === 0;

  return (
    <Container>
      <Row>
        <Column>
          <ConnectedAssetSetupClue />
          <SubscriptionLimitClue />
          <NestedNodeBreadcrumb
            id="nodes-header"
            node={node}
            onClick={handleOnNodeSelect}
            firstItemTargetUrl="/nodes"
          />
          <AllObjectsHeader
            accessRights={accessRights}
            additionalCreateMenuItems={additionalCreateMenuItems}
            isLoading={isPreLoading}
            node={node}
            onConfirmDelete={handleOnConfirmDelete}
            isParentNode={isNotEmpty(nodes) || isNotEmpty(tags) || isNotEmpty(assets)}
          />
          <Search scanSearchSource="" showFilters={!!(!isAllObjects || !showNoAssetsFound)} />
          {isAllObjects && showNoAssetsFound && <AllObjectsNoAssetsFoundClue nodeId={node?.id} />}
          {!isInitialLoadingAccessRights && !isInitialLoadingNode && isAllObjects && items.length > 0 && (
          <AllObjectsList
            accessRights={accessRights}
            isLoading={showLoader}
            hasMore={hasMore}
            items={items}
            onItemRemoved={handleOnItemRemoved}
            loadMore={fetchMoreData}
            node={node}
          />
          )}
          {showSkeleton && (<ListSkeleton withChips={!!search.options.length} />)}
          <Loader loading={isAllObjects && showLoader && !showSkeleton} />
          {(showNothingFound || showNothingFoundSearch) && (
            <div className="no-items-found-text">
              <FormattedMessage id="label.no_items_found" />
            </div>
          )}
        </Column>
      </Row>
    </Container>
  );
}

AllObjects.propTypes = {
  additionalCreateMenuItems: PropTypes.node,
  rules: rulesShape,
  subscription: subscriptionShape,
  flipper: PropTypes.shape({
    allobjectFilterActive: PropTypes.bool.isRequired,
  }).isRequired,
};

export default withFlipper(withSubscription(withRules(AllObjects)));
