import React from 'react';
import PropTypes from 'prop-types';
import treeItemShape from '../../shapes/treeItemShape';
import Icon from '../Icon';

function TreeItem({
  treeItem, onChange, selectedItems, disabled, showIsEndress,
}) {
  const isDetailsOpen = selectedItems.find((selectedItem) => selectedItem.code === treeItem.code);

  const isChildSelected = (item) => (
    item.children.some((child) => {
      if (selectedItems.find((selectedItem) => selectedItem.code === child.code)) return true;
      if (child.children.length > 0) return isChildSelected(child);
      return false;
    })
  );

  const getSelectedChildrenCount = (item) => item.children.reduce((acc, child) => {
    let count = acc;
    if (selectedItems.find((selectedItem) => selectedItem.code === child.code)) count += 1;
    if (child.children.length > 0) count += getSelectedChildrenCount(child);
    return count;
  }, 0);

  const isEndress = showIsEndress && (
    treeItem.tenant?.name && treeItem.tenant?.public ? (
      <span className="tenant-option-icon">
        <Icon name="icon3-eh-radio-button-checked" />
      </span>
    ) : null
  );

  return (
    <li key={`tree-item-${treeItem.id}`}>
      <details open={isDetailsOpen} disabled={disabled}>
        <summary aria-label={treeItem.children.length > 0}>
          <input
            id={`${treeItem.code}-checkbox`}
            type="checkbox"
            checked={!!selectedItems.find(((i) => i.code === treeItem.code))}
            value={treeItem.code}
            disabled={disabled}
            onChange={() => onChange(treeItem)}
          />
          <label title={treeItem.name} htmlFor={`${treeItem.code}-checkbox`}>
            {treeItem.name}
          </label>
          {isEndress}
          {isChildSelected(treeItem) && <span className="badge">{getSelectedChildrenCount(treeItem)}</span>}
        </summary>
        {treeItem.children.length > 0 && (
          <ul>
            {treeItem.children.map((childItem) => (
              <TreeItem
                key={`child-${childItem.id}`}
                treeItem={childItem}
                selectedItems={selectedItems}
                onChange={onChange}
                disabled={disabled}
                showIsEndress={showIsEndress}
              />
            ))}
          </ul>
        )}
      </details>
    </li>
  );
}

TreeItem.propTypes = {
  treeItem: treeItemShape.isRequired,
  selectedItems: PropTypes.arrayOf(treeItemShape).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showIsEndress: PropTypes.bool,
};

export default TreeItem;
