import React from 'react';
import { TypeaheadBox, withIntl, intlShape } from 'lcm-iot-commons';
import { getTimeZones } from '../../utils/timeZones';

function TimeZoneTypeahead(props) {
  const options = getTimeZones();
  const { intl } = props;
  return (
    <TypeaheadBox
      {...props}
      id="time-zone-typeahead"
      options={options}
      labelKey="label"
      valueKey="identifier"
      canEdir={false}
      placeholder={intl.formatMessage({ id: 'label.time_zone' })}
    />
  );
}

TimeZoneTypeahead.propTypes = {
  intl: intlShape.isRequired,
};

export default withIntl(TimeZoneTypeahead);
