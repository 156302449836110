/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import { FormattedMessage, injectIntl } from 'react-intl';

import NodeItem from '../Nodes/NodeItem';
import NestedNodeBreadcrumb from '../Breadcrumb/NestedNodeBreadcrumb';
import InstrumentationItem from '../Instrumentations/InstrumentationItem';
import Loader from '../Loader';
import List from '../List/List';
import {
  intlShape,
  nodeShape,
} from '../../shapes';

import {
  loadInstrumentations,
  loadNextInstrumentations,
  loadNextNodes,
  loadNode,
  loadNodes,
  fetchAccessRights,
} from '../../api';

import {
  isSomething,
  handleUnknownErrors,
} from '../../utils';

import { withRules, rulesShape, withConfiguration } from '../../context';
import { PermissionType } from '../../constants';

export class AllObjectsSelectModal extends Component {
  static propTypes = {
    canSelectInstrumentations: PropTypes.bool,
    canSelectRootNode: PropTypes.bool,
    permissionsFilter: PropTypes.arrayOf(PropTypes.oneOf([PermissionType.READ, PermissionType.UPDATE])),
    currentNode: nodeShape,
    intl: intlShape.isRequired,
    onClose: PropTypes.func,
    onSelectInstrumentation: PropTypes.func,
    onSelectNode: PropTypes.func,
    type: PropTypes.oneOf(['move', 'select', 'assign']),
    rules: rulesShape,
    configuration: PropTypes.shape({ appName: PropTypes.string }),
  };

  static defaultProps = {
    type: 'move',
    permissionsFilter: [PermissionType.READ],
  };

  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.loadData = this.loadData.bind(this);
    this.doLoadData = this.doLoadData.bind(this);
    this.handleOnNodeSelect = this.handleOnNodeSelect.bind(this);
    this.handleOnInstrumentationSelect = this.handleOnInstrumentationSelect.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.state = { fetching: true, submitting: false, items: [] };
  }

  componentDidMount() {
    this.loadData();
  }

  handleOnNodeSelect(node) {
    if (!node) {
      this.setState({ node: null });
    }
    this.setState({
      instrumentation: null, nodes: null, instrumentations: null, items: [],
    }, () => {
      this.loadData(node ? node.id : undefined);
    });
  }

  handleOnInstrumentationSelect(instrumentation) {
    const { intl } = this.props;
    this.setState({
      nodes: null, instrumentations: null, items: [], instrumentation,
    });
    fetchAccessRights('Instrumentation', instrumentation.id)
      .then((instrumentationAccessRights) => this.setState({ instrumentationAccessRights }))
      .catch((errors) => handleUnknownErrors(errors, intl.formatMessage({ id: 'api.error.unknown' })));
  }

  async handleSelect() {
    const { node, instrumentation } = this.state;
    const { onSelectNode, onSelectInstrumentation } = this.props;
    this.setState({ submitting: true });
    if (instrumentation) {
      await onSelectInstrumentation(instrumentation);
    } else {
      await onSelectNode(node);
    }
    this.setState({ submitting: false });
  }

  handleLoadMore() {
    this.loadData();
  }

  loadData(nodeId) {
    const { intl } = this.props;
    this.doLoadData(nodeId).catch((errors) => {
      handleUnknownErrors(errors, intl.formatMessage({ id: 'api.error.unknown' }));
    });
  }

  async doLoadData(nodeId) {
    const {
      nodes, instrumentations, items,
    } = this.state;
    const {
      canSelectInstrumentations, rules,
    } = this.props;
    this.setState({ fetching: true });

    let updatedItems = items;
    let loadMore = true;
    let { node, nodeAccessRights } = this.state;

    if (nodeId) {
      node = await loadNode(nodeId, { include: 'parent, type' });
      nodeAccessRights = await fetchAccessRights('Node', nodeId);
      this.setState({ node, nodeAccessRights });
    }

    if (!nodes || nodes.nextPageUrl) {
      const filter = {
        parent_id: node ? node.id : 'null',
        include: 'type, type.parent',
        order_by: 'name',
        per_page: 25,
        permission: this.props.permissionsFilter.join(','),
      };
      const nodesResult = await (nodes ? loadNextNodes(nodes.nextPageUrl, filter) : loadNodes(filter));
      const allowedNodes = nodesResult.nodes.filter((item) => !rules.node(item).get('preventMovingInto'));
      loadMore = !nodesResult.nextPageUrl;
      updatedItems = updatedItems.concat(allowedNodes);
      this.setState({ nodes: nodesResult });
    }

    if (canSelectInstrumentations && loadMore && (!instrumentations || instrumentations.nextPageUrl)) {
      const filter = {
        include: 'type',
        order_by: 'tag',
        node_id: node ? node.id : 'null',
      };
      const instrumentationsResult = await (instrumentations ? loadNextInstrumentations(instrumentations.nextPageUrl) : loadInstrumentations(filter));
      updatedItems = updatedItems.concat(instrumentationsResult.instrumentations);
      this.setState({ instrumentations: instrumentationsResult });
    }

    this.setState({ items: updatedItems, fetching: false });
  }

  renderItems() {
    const { currentNode } = this.props;
    const { items, node } = this.state;

    return items.map((item) => {
      switch (item.itemType) {
        case 'node':
          return (
            <NodeItem
              key={`n${item.id}`}
              node={item}
              onClick={this.handleOnNodeSelect}
              disabled={currentNode && currentNode.id === item.id}
              hideStatus
            />
          );
        case 'instrumentation':
          return (
            <InstrumentationItem
              key={`i${item.id}`}
              instrumentation={item}
              node={node}
              onClick={this.handleOnInstrumentationSelect}
              hideStatus
            />
          );
        default:
          return null;
      }
    });
  }

  render() {
    const {
      onClose,
      type,
      canSelectRootNode,
      currentNode,
      intl,
    } = this.props;
    const {
      items, instrumentation, instrumentations, node, nodes, fetching, submitting, nodeAccessRights, instrumentationAccessRights,
    } = this.state;

    const hasMore = !nodes || isSomething(nodes.nextPageUrl) || !instrumentations || isSomething(instrumentations.nextPageUrl);

    const showMoveButton = instrumentation || node || canSelectRootNode;

    const actionTranslationKey = ((node || instrumentation) && type === 'assign' && 'button.assign_to')
        || (type === 'select' && 'button.select')
        || (((node || instrumentation) && 'button.move_to') || (type === 'assign' && 'button.assign_to_root') || 'button.move_to_root');

    const actionTranslationTarget = (instrumentation && instrumentation.tag) || (node && node.name);
    const moveNotAllowed = type === 'move' && currentNode && currentNode.parent && node && currentNode.parent.id === node.id;
    const noMovePermissions = type !== 'select'
        && ((!instrumentationAccessRights && node && !nodeAccessRights)
            || (instrumentationAccessRights && !instrumentationAccessRights.canUpdate)
            || (nodeAccessRights && !nodeAccessRights.canUpdate));

    const noItemsAvailable = type === 'select' || fetching || showMoveButton ? null : (
      <p id="no-nodes-select-list">
        {intl.formatMessage({ id: 'nodes.move_no_nodes' })}
      </p>
    );

    return (
      <Modal show restoreFocus={false} onHide={onClose} bsSize="large" backdrop={fetching ? 'static' : true}>
        <Modal.Header closeButton>
          <Modal.Title>
            <NestedNodeBreadcrumb
              id="nodes-header"
              node={node}
              current={instrumentation && instrumentation.tag}
              onClick={this.handleOnNodeSelect}
              firstItemTargetUrl="/nodes"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {items.length > 0 ? (
            <List id="nodes-select-list">
              {/* eslint-disable-next-line no-return-assign */}
              <div style={{ maxHeight: '700px', overflow: 'auto' }} ref={/* istanbul ignore next */(ref) => this.scrollParentRef = ref}>
                <InfiniteScroll
                  initialLoad={false}
                  loadMore={this.handleLoadMore}
                  hasMore={!fetching && hasMore}
                  useWindow={false}
                  threshold={1000}
                  getScrollParent={/* istanbul ignore next */() => this.scrollParentRef}
                >
                  {this.renderItems()}
                </InfiniteScroll>
              </div>
            </List>
          ) : noItemsAvailable }
          <Loader loading={fetching} />
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-group justify-right">
            {showMoveButton ? (
              <button
                id="button-move"
                type="button"
                className="btn btn-primary text-truncate"
                onClick={this.handleSelect}
                disabled={moveNotAllowed || noMovePermissions || fetching || submitting}
              >
                <FormattedMessage
                  id={actionTranslationKey}
                  values={{ target: actionTranslationTarget }}
                />
                { submitting ? (<i className="btn-spinner" />) : null}
              </button>
            ) : null}
            <button id="button-cancel" type="button" className="btn btn-default" onClick={onClose}><FormattedMessage id="button.cancel" /></button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default injectIntl(withConfiguration(withRules(AllObjectsSelectModal)));
